import React from 'react'
import LexicalTextEditor from './TextEditor/LexicalTextEditor';

export const StaticRichText = ({ label, richContent }) => {

  return (
      <LexicalTextEditor
          label={label}
          value={richContent}
          readOnly={true}
      />
  )
}

