import { isEmpty } from "lodash";
import { ButtonGhost, SANS_2, colors, CheckboxInput, Accordion, makeArrayFromLength } from "oolib";
import { ButtonPrimary } from "oolib";
import { SANS_3, SkeletonLoader } from "oolib";
import React, { useState, useEffect, Fragment } from "react";

import { __GetTagTypeConfig } from "../../../../../utils/getters/gettersV2";




import {
  StyledFilterAccordionHeader,
  SideFilterHeaderStyled,
  SideFilterListStyled,
  SideFilterListWrapper,
  StyledAsideFilterContainer,
  StyledAsideFilterContentsWrapper,
  StyledToggleButtonWrapper,
  StyledAsideFilter,
  StyledOptionWrapper,
} from "./styled";
import { FilterInput } from "../../../../inputs/DynamicTagsInputs/FilterInput";
import { BadgeNumber } from "../../../../generalUI/BadgeNumber";


export const FilterAccordion = ({
  id,
  title,
  options,
  values,
  onChange: parentOnChange,
  children,
}) => {
  const [expand, setExpand] = useState(localStorage.getItem(id) || false);

  const handleOnClick = () => {
    setExpand((prev) => {
      return !prev;
    });
  };

  const handleOptionClick = (option) => {
    let isSelected = values.some((d) => d.value === option.value);
    if (!isSelected) {
      values.push(option);
    } else {
      values = values.filter((d) => d.value !== option.value);
    }

    parentOnChange({ id: title, filterType: "tagTypes" }, values);
  };

  return (
    <Accordion
      expand={expand}
      CustomHeader={
        <StyledFilterAccordionHeader onClick={handleOnClick}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <SANS_3 semibold>
              {__GetTagTypeConfig(title)?.content?.title || title}
            </SANS_3>
            {values && values.length ? <BadgeNumber number={values.length} /> : null}
          </div>
          <ButtonGhost icon={expand ? "Minus" : "Plus"} S />
        </StyledFilterAccordionHeader>
      }
      >
    <Fragment>
      {children}
      {options?.map((d) => (
        <StyledOptionWrapper
          key={d.value}
          onClick={() => {
            handleOptionClick(d);
          }}
        >
          <CheckboxInput option={d} value={values} />
        </StyledOptionWrapper>
      ))}
      </Fragment>
    </Accordion>
  );
};

const getAllSelectedCount = (object) => {
  if (isEmpty(object)) return 0;

  let count = 0;

  for (let i in object) {
    count += object[i].length;
  }

  return count;
};

export default function AsideFilter({
  onChange,
  selectedValues,
  updateActiveFilters,
  headerRef,
  activeFilters,
  filterConfig,
  presetActiveFilters,
  contentTypes
}) {
  const expandedWidth = 250;
  const [width, setWidth] = useState(expandedWidth);
  const [headerHeight, setHeaderHeight] = useState(undefined);
  
  useEffect(
    () =>
      headerRef?.current &&
      setHeaderHeight(headerRef?.current.getBoundingClientRect().height),
    []
  );

  const handleClearAllTags = () => {
    //empty all selected filter values
    updateActiveFilters(null, true);
  };

  const handleToggleSidebar = () => {
    setWidth((prev) => (prev === 0 ? 250 : 0));
  };

  const allCount = getAllSelectedCount(selectedValues);
  return (
    <StyledAsideFilter top={headerHeight}>
      <StyledAsideFilterContainer width={width}>
        <StyledAsideFilterContentsWrapper
          headerHeight={headerHeight}
          width={expandedWidth}
        >
          {
             (
              <Fragment>
                <SideFilterHeaderStyled>
                  <SANS_3  semibold capitalize>
                    Filters
                  </SANS_3>
                  <ButtonGhost onClick={handleClearAllTags}>
                    Clear All
                  </ButtonGhost>
                 
                </SideFilterHeaderStyled>
                <SideFilterListWrapper>
                  <SideFilterListStyled>
                    {Object.keys(filterConfig).map((key) => {
                      let {  filterId } = filterConfig[key];
                      
                      return (
                        <FilterInput                       
                          key={filterId}
                          comp='AccordionMultiSelect'                   
                          value={selectedValues[filterId] || []}                       
                          onChange={(_, value) => {
                            onChange({
                              id: filterId,                          
                              value,
                            });
                          }}
                          filterConfig={filterConfig[key]}
                          activeFilters={activeFilters}
                          presetActiveFilters={presetActiveFilters}
                          contentTypes={contentTypes}
                        />
                      );
                    })}
                  </SideFilterListStyled>
                </SideFilterListWrapper>
              </Fragment>
            )
          }
        </StyledAsideFilterContentsWrapper>
        <StyledToggleButtonWrapper>
          <ButtonPrimary onClick={handleToggleSidebar} icon="Faders" />{" "}
          {allCount !== 0 && (
            <div
              style={{ position: "absolute", top: "-1rem", right: "-.5rem" }}
            >
              <BadgeNumber number={allCount} />
            </div>
          )}
        </StyledToggleButtonWrapper>
      </StyledAsideFilterContainer>
    </StyledAsideFilter>
  );
}
