import axios from 'axios'
import { nanoid } from 'nanoid'
import { formatTextToCamelCase, getVal, setVal, toArray } from 'oolib'
import uuid from 'uuid'
import { queryClient } from '../../index'
import Moment from 'moment';

//should deprecate
export const getQueryParam = (string, query) => {
  let params = new URLSearchParams(string);
  return params.get(query);
};

//should deprecate since we now have useSearchParamsState
export const injectQueryParamCarefully = (key, value, location, history) => {
  let currSearch = location.search;
  //no query string
  if (!currSearch) {
    history.push({ search: `?${key}=${value}` });
  }
  //query string exists, but doesnt have 'key' param
  else if (!currSearch.includes(key)) {
    history.push({ search: `${currSearch}&${key}=${value}` });
  }
  //query string exists, AND has ONLY 'key' param.
  //so we need to replace the key param only
  else if (currSearch.split("&").length === 1) {
    history.push({ search: `?${key}=${value}` });
  }
  //query string exists, AND has 'activeTab' param along with others.
  else if (currSearch.split("&").length > 1) {
    let toPush =
      currSearch
        .split("&")
        .filter((d) => !d.includes(key))
        .join("&") + `&${key}=${value}`;
    history.push({ search: toPush });
  }
};




export const imageUploader = async (
  formData,
  options = null,
  callback = null
) => {
  let {
    deployment: { _MediaManagerService, _MaxBackendUploadSize },
  } = queryClient.getQueryData("platformConfigs");

  let apiString =
    _MediaManagerService === "aws"
      ? "/api/mediaUpload/aws"
      : "/api/mediaUpload";

  // --- MAGIC NUMBER CHECK --- //
  //get files. we have just made life difficult for ourselves cuz of formdata
  // const allowedFormats = options.allowedFormats || formData.get('allowedFormats')?.split(',');

  try {
    let hasLargeFile =
      options.jsonFileData &&
      options.jsonFileData.some(
        (f) =>
          f.size / (1000 * 1000) >
          _MaxBackendUploadSize /*cuz we want the file size in MB */
      );
    if (hasLargeFile) {
      let formats = options.jsonFileData.map((f) =>
        f.type.slice(f.type.indexOf("/") + 1)
      );
      if (formats.some((format) => !options.allowedFormats.includes(format))) {
        options.SET_ALERT_BANNER(
          "Upload failed. One or more files had an invalid format",
          "red",
          4000
        );
        callback && callback("error", null);
        return;
      }
      let promises = options.jsonFileData.map(async (f) => {
        try {
          //1 get signed url generated from our backend
          const signedUrlRes = await axios.get(
            `${apiString}/getSignedUrl/${encodeURIComponent(
              options.folderName + "/" + uuid.v4()
            )}?originalFilename=${f.name}&type=${f.type}`
          );

          //2 upload image directly to cloud storage
          await axios.put(signedUrlRes.data.signedUrl, f, {
            headers: { "Content-Type": f.type },
            onUploadProgress: (event) => {
              options.handleUploadProgess &&
                options.handleUploadProgess(
                  Math.round((100 * event.loaded) / event.total)
                );
            },
          });

          //3 now we need to get the data of the file we just uploaded from our backend.
          const res = await axios.get(
            `${apiString}/getFile/${encodeURIComponent(
              signedUrlRes.data.fileNameInGcs
            )}`
          );

          return res.data;
        } catch (err) {
          console.log("err in largefile upload promise map", err);
          return "error";
        }
      });
      const results = await Promise.all(promises);
      if (results.some((d) => d === "error")) {
        callback && callback("error", results);
      } else {
        callback && callback("success", results);
      }

      return;
    }
    //else

    const config = {
      headers: { "Content-Type": "application/json" },
      onUploadProgress: (event) => {
        options.handleUploadProgess &&
          options.handleUploadProgess(
            Math.round((100 * event.loaded) / event.total)
          );
      },
    };
    const urlParam = options && options.type ? options.type : "files"; //defaults to a basic file upload ( on the backend, a file upload simply uploads whats given without transformations)
    const res = await axios.post(`${apiString}/${urlParam}`, formData, config); //PENDING : I think the config const needs to be passed into this POST as well...

    if (res.data.name === "Error") {
      options.SET_ALERT_BANNER(res.data.message, "red", 4000);
      callback && callback("error", res.data);
    } else if (
      Array.isArray(res.data) &&
      res.data.some((d) => d.name === "Error")
    ) {
      options.SET_ALERT_BANNER(
        res.data.filter((d) => d.name === "Error")[0].msg,
        "red",
        4000
      );
      callback && callback("error", res.data);
    } else {
      callback && callback("success", res.data);
    }
  } catch (err) {
    if (err)
      console.log(
        "err in utils > general > imageUploader",
        err.response ? err.response : err
      );
    options.handleErrorFeedback &&
      options.handleErrorFeedback("Error! Try Again"); //reset uploading status to false
    options.SET_ALERT_BANNER(
      err.response
        ? err.response.data.msg
        : "Server Error. Media Upload Failed",
      "red",
      4000
    );
  }
};


export const checkForUnpublishedClone = (publishedId, editPublishedArray) => {
  let cloneExists = false;
  editPublishedArray.map((d) => {
    if (publishedId === d.kp_og_published_doc) {
      cloneExists = true;
      return;
    }
  });
  return cloneExists;
};


/**
 * this returns false if the property is falsy OR an empty obj OR an empty array
 * ( yes the typeof and object.keys check works for arrays as well )
 */
export const propertyExists = (property) => {
  return !property ||
    (typeof property === "object" && Object.keys(property).length === 0)
    ? false
    : true;
};

export const formatTitle = (value) => {
  if (!value) return null;

  let formattedTitleText = value
    .trim()
    .split(" ")
    .map((word) => {
      return word !== word.toUpperCase()
        ? word[0].toUpperCase() + word.slice(1)
        : word;
    })
    .join(" ");

  return formattedTitleText;
};

export const formatText = {
  underscoreToTitle: (string) => {
    let newString = string.replace(/_/g, " ");
    return newString[0].toUpperCase() + newString.slice(1);
  },
  textToCamelcase: (string) => {
    const regex = /[,\/-]/gi;
    return string
      ? string
          .trim()
          .replace(regex, "")
          .split(" ")
          .filter(Boolean)
          .map((word, i) => {
            let reformattedWord =
              i === 0
                ? word[0].toLowerCase() + word.slice(1)
                : word[0].toUpperCase() + word.slice(1);
            return reformattedWord;
          })
          .join("")
      : "";
  },
  //only first letter capital of entire text string
  toSentenceCase: (str) => str.charAt(0).toUpperCase() + str.substr(1),
  //other possibilities could be:
  //camelToTitle
  //camelToUpper
  //camelToLower
};

export const handleSetOtherValuePaths = ({
  setValuePath,
  setValuePathFn,
  setValuePathArgs,
  content,
  value,
}) => {
  const setValuePathFnsLookup = {
    textToCamelCase: formatTextToCamelCase,
    genNanoid: (v, { length } = {}) =>
      `${formatTextToCamelCase(v)}_${nanoid(length || 5)}`,
    optionToRichText: (v) => ({ blocks: [{ text: v.display }], entityMap: {} }),
    singleTagToRichText: (v) => v.data[0].display, // rename to ‘singletagtodisplaystring’ once cata platform is done
    percentIncrease: (v, { percent }) => {
      let num = parseInt(v);
      if (Number.isNaN(num)) return "";
      //else
      return num + num * (percent / 100);
    },
    linkEmbedWithInputToText: (v) => v.metadata?.title || v.url,
    cata_convertFakenamesToRepeaterTabs: (v) => {
      return typeof v === "string"
        ? [
            //idi
            {
              tabDisplay: v,
              tabValue: formatText.textToCamelcase(v),
            },
          ]
        : // fgd
          v.map((d) => ({
            tabDisplay: d.name,
            tabValue: formatText.textToCamelcase(d.name),
          }));
    },
    cata_nameFromParticipantIdToRichText: (v) => {
      //delete once cata is done
      let split = v.data[0].display.split("-");
      let len = split.length;
      let name = split[len - 2];
      return name;
    },
    gelabs_nameFromParticipantIdToRichText: (v) => {
      //delete once cata is done
      return v.data.map(d => d.display.split("-")[0]).join(', ');
      
    },
    tci_setPropModifierSideEffectValue: (v) => {
      if (["$setToTargetValue", "$getDaysDiff"].indexOf(v.value) !== -1) {
        return v.value;
      } else {
        return undefined;
      }
    },
    bosh_calculatePercentage: (value, setValuePathArgs, content) => {

      const marksObtained = parseFloat(
        getVal(content, setValuePathArgs.marksScoredValuePath)
      );
      const totalMarks = parseFloat(
        getVal(content, setValuePathArgs.totalMarksValuePath)
      );

      if (Number.isNaN(marksObtained) || Number.isNaN(totalMarks) || totalMarks===0) return "";

      return ((marksObtained / totalMarks) * 100).toFixed(2) + "%";
    },
  };
  let setValuePaths = toArray(setValuePath);
  let setValuePathFns = setValuePathFn ? toArray(setValuePathFn) : [];
  setValuePaths.forEach((path, idx) => {
    let thisSetValuePathFn = setValuePathFns[idx];
    let valToSet = thisSetValuePathFn
      ? setValuePathFnsLookup[thisSetValuePathFn](value, setValuePathArgs,content)
      : value;
    setVal(content, path, valToSet);
  });
};





export const segrigateDocs = (docs, sortByPath, options = {}) => {
  let newSegrigatedObj = {};
  docs.map((d, i) => {
    let sortByValue = getVal(d, sortByPath);
    if (sortByValue || !options.skipUndefined) {
      if (Array.isArray(sortByValue)) {
        sortByValue.map((v) => {
          if (!newSegrigatedObj[v]) {
            newSegrigatedObj[v] = [];
          }
          newSegrigatedObj[v].push(d);
        });
      } else {
        if (!newSegrigatedObj[sortByValue]) {
          newSegrigatedObj[sortByValue] = [];
        }
        newSegrigatedObj[sortByValue].push(d);
      }
    }
  });

  //an extra bit, if an id and title is provided change the data structure to
  /*
  {
    x : {
      id : xx,
      title: xx,
      ary: []
    },
    y : ...
  }
  */

  if (options.idPath || options.titlePath) {
    Object.values(newSegrigatedObj).map((v, i) => {
      let key = Object.keys(newSegrigatedObj)[i];
      newSegrigatedObj[key] = {
        id: options.idPath ? getVal(v[0], options.idPath) : undefined,
        title: options.titlePath ? getVal(v[0], options.titlePath) : undefined,
        [options.aryKey ? options.aryKey : "ary"]: v,
      };
    });
  }
  return newSegrigatedObj;
};

export const convertToGcsStructure = (value) => {
  //backward compat --> shifting from cloudinary to gcs
  if (value.imgCropData && value.imgData) {
    //means this is the old structure
    if (value.imgData.length > 0) {
      //restructure fields
      const { imgData, imgCropData } = value;
      let newImgData = {
        publicUrl: imgData[0].secure_url,
        id: imgData[0].public_id,
        cropX: imgCropData.x,
        cropY: imgCropData.y,
      };
      return [{ ...imgData[0], ...newImgData }];
    } else {
      return [];
    }
  }
  //else return as is
  return value;
};

export const convertResToGcsStructure = (value) => {
  //backward compat --> shifting from cloudinary to gcs

  const extractFormat = (url) => {
    let urlParts = url.split(".");
    return urlParts[urlParts.length - 1];
  };

  if (value.resourceData) {
    //means this is the old structure
    if (value.resourceData.length > 0) {
      //restructure fields
      const { resourceData } = value;
      let newResData = [];
      resourceData.map((d) => {
        newResData.push({
          ...d,
          publicUrl: d.secure_url,
          id: d.public_id,
          mediaLink:
            d.resource_type === "raw" ? d.secure_url : d.eager[0].secure_url,
          format: extractFormat(d.secure_url),
        });
      });

      return newResData;
    } else {
      return [];
    }
  }
  //else return as is
  return value;
};

export const copyToClipboard = (inputId) => {
  let textElem = document.getElementById(inputId);
  textElem.select();
  document.execCommand("copy");
  alert("link successfully copied to clipboard!");
};

export const getFormatFromFilename = (filename) => {
  let ary = filename.split(".");
  return ary[ary.length - 1];
};

export const simpleDrillDown = (
  blocks,
  callback,
  ops = {},
  subSectionData = undefined
) => {
  // let subSectionKey =
  // 	ops.isSubSectionCheck_key || ops.subSectionKey || 'subSectionName'
  let blocksKey = ops.blocksAry_key || ops.blocksKey || "blocks";
  return blocks.map((block) => {
    if (block[blocksKey]) {
      subSectionData = JSON.parse(JSON.stringify(block));
      delete subSectionData[blocksKey];
      return simpleDrillDown(block[blocksKey], callback, ops, subSectionData);
    } else {
      return callback(block, subSectionData);
    }
  });
};

export function stitchLink(data, tableConfigLink) {
  /**
   * idPath: ['a','b']
   * desc -> here array means that both the items will be stitched one after the other as per config
   * now imagine a config like this -> idPath: ['a,c', b]
   * desc -> in this case, the a,c will be parsed such that the first truthy value found for
   * either of the paths, will be considered for ary index 0
   *
   * tableConfigLink.target = '_blank' : this will prepare the full url including hostname & port so that on the outside we can open in new tab
   */

  const { idPath, idAs, idName, urlString, suffix, target } = tableConfigLink;
  let isArray = Array.isArray(idPath);
  let idString = "";
  const splitTrim = (str) => str.split(",").map((d) => d.trim());
  if (isArray) {
    let ids = idPath.map((p) => getVal(data, splitTrim(p)));
    //if any of the paths gives falsy value then return undefined
    if (ids.some((id) => !!id === false)) return undefined;

    idAs.map((as, i) => {
      if (as === "param") {
        ids[i] = "/" + ids[i];
      } else if (as === "query") {
        ids[i] = `?${idName ? idName[i] : "id"}=${ids[i]}`;
      }
    });
    idString = ids.join("");
  } else {
    let id = getVal(data, splitTrim(idPath));
    if (!id) return undefined;
    idString =
      idAs === "query"
        ? `?${idName || "id"}=${id}`
        : idAs === "param"
        ? `/${id}`
        : id;
  }

  const hostAndPort = target === "_blank" ? window.location.origin : "";

  return `${hostAndPort}${urlString}${idString}${suffix || ""}`;
}

export const getPercentLessonComplete = (user, list) => {
  let contentCompletionStatus = user.contentCompletionStatus;

  let percent = 0;
  if (
    list &&
    list.length > 0 &&
    contentCompletionStatus &&
    contentCompletionStatus.length > 0
  ) {
    let count = 0;
    list.map((l) => {
      if (contentCompletionStatus.some((c) => c.contentId === l.resId._id)) {
        count = count + 1;
      }
    });
    percent = Math.floor((count / list.length) * 100);
  }

  return percent;
};

export const getTodayString = () => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return today.toISOString();
};

export const serialize = (configs) =>
  ["boolean", "string", "number"].indexOf(typeof configs) === -1
    ? encodeURIComponent(JSON.stringify(configs))
    : configs;

export const genErrMsgs = ({ err, cases }) => {
  return toArray(err.response?.data?.errors || []).map((error) => {
    return cases[error?.id] || cases.default || "Server Error";
  });
};

export const downloadCsvFile = ({ data,fileName }) => {
  const element = document.createElement("a");

  const BOM = "\uFEFF"; //ADDED FOR HINDI CONTENT
  const csvContent = BOM + data;
  const file = new Blob([csvContent], {
    type: "text/csv;charset=utf-8,%EF%BB%BF",
  });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName ? fileName : "OKE"}_export ${Moment().format(
    "MMMM Do YYYY, h-mm-ss a"
  )}.csv`;
  document.body.appendChild(element); // Required for this to work in FireFox

  element.click();
};


 export const handleDataExport =async ({refetch,setExporting}) => {
  try {
    // console.log({csvData})
   setExporting(true) 
  let { data:csvData}= await refetch()    
   setExporting(false) 
   downloadCsvFile({data:csvData})    
  } catch (err) {
    console.log('get csv data err', err)
  }
}