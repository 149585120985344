import React, { useEffect, useRef, useState } from "react";

import { Container, Section, Wrapper1500, colors, getBreakPoint, toArray, useScreenWidth } from "oolib";
import FilterModule from "../../components/discovery/FilterModule";
import KeywordSearch from "../../components/discovery/KeywordSearch";
import {
  useGetDataLazy,
} from "../../utils/react-query-hooks/getData";
import { useGetAllUsersLazy } from '../../utils/react-query-hooks/users/useGetAllUsersLazy';
import {
  StyledContentDiscoveryWrapper,
  StyledFilterSortWrapper,
} from "../PublishedListing/styled";

import Sort from "../../components/discovery/Sort";
import { useDebounce } from "../../utils/customHooks";
import ListingModule from "./comps/ListingModule";

import PageCover from "../../components/organisms/PageCover";
import { useAppSettingsContext } from "../../contexts/appSettingsContext";
import { trackEventGA } from "../../trackers/GA/trackEventGA";

import ErrorStates from "../../components/ErrorStates";

import { buildListingPageQueryForKS } from "../../utils/okf-ks-utils/buildListingPageQueryForKS";
import { valueNotFalsyOrEmptyAryObj } from "../../utils/validation/validatorFns";
import { useExtractInitBackendSortFromTableConfig } from "./utils/useExtractInitBackendSortFromTableConfig";
import { defaultListingConfigForCardOrList } from "../PublishedListing/config/defaultListingConfigForCardOrList";
import { genBaseTableConfig } from "../PublishedListing/config/genBaseTableConfig";

import { checkAccessToDownloadCsv } from "./utils/checkAccessToDownloadCsv";
import { queryClient } from "../..";
import { useGetExportToCsvContent } from "../../utils/react-query-hooks/exportToCsv";
import { __GetContentTypeConfigNew } from "../../utils/getters/gettersV2";


const { white } = colors;


const ListingPage = ({
  contentTypes,
  configs = {},
  presetActiveFilters = [],
  collectionId,
  showCount = true,
  broadcastTotalCount, // fn that captures the totalcount for parent. used in linkormebedmodallex
  showSearchInput : _showSearchInput = true, //sometimes, we want to handle the search input UI externally and trigger keyword search config from there. In such cases, we want to disable the native SearchInput design. eg LinkOrEmbedModalLex
  searchTermInputValue: searchTermInputValueFromParent, // in some cases we allow the parent to define its own searchinput ui, and control the searchterminputvalue from there. eg LinkOrEmbedModalLex
  sectionTitle = undefined, //sometimes if we are not showing the cover, we might want a section title instead
  sectionLink = undefined,
  enableLazyLoad = true,
  ListingWrapper,
  enableContainerQuery,
  inTCI,
  builderProps,
  listingExport
}) => {
  const {
    sortConfig,
    getDataQuery = 'useGetDataLazy',
    getDataConfig = {},
    filterConfig: {
      filters,
      filterModuleOrientation
    } = {},
    listingStyle = "CardsStyle",
    listingConfig: _listingConfig,
    coverConfig,
    keywordSearchConfig,
    tagCategory,
    tag
  } = configs;

  let listingConfig =
    _listingConfig ||
    (
      listingStyle === "TableStyle" 
      ? genBaseTableConfig(contentTypes[0])
      : ['CardsStyle', 'ListStyle'].includes(listingStyle)
        && defaultListingConfigForCardOrList
    ) ||
    {};

  const { APP_SETTINGS } = useAppSettingsContext();

  const lang = APP_SETTINGS.lang;
  // const newListingConfigs = cloneDeep(listingConfig);

  // if (lang.value !== "en") {
  //   newListingConfigs.configs.title = [`translations.${lang.value}.main.title`];
  //   newListingConfigs.configs.subtitle = [
  //     `translations.${lang.value}.main.subtitle`,
  //   ];
  // }

  // listingConfig = newListingConfigs;

  /**
   * we are defaulting find query to fetch only published pieces, but
   * it can be overwritten by the findQuery prop, if one chooses to.
   */
  const findQuery = {
    kp_published_status: "published",
    ...(getDataConfig.findQuery || {}),
  };
  let { data : userData } = queryClient.getQueryState('userData')

  //session storage
  const sessionStorageKey = `ListingPage__${
    collectionId ? collectionId + "__" : ""
  }${toArray(contentTypes).join("_")}`;
  const sessionData = JSON.parse(sessionStorage.getItem(sessionStorageKey));

  //local states
  const [activeFilters, setActiveFilters] = useState(
    sessionData?.activeFilters || []
  );

  /*
  reset active filters, if the content types being rendered by this listing page changes
  (happens when you shift from one page using ListingPage component to another page using ListingPage component)
  */
  useEffect(() => {
    setActiveFilters(sessionData?.activeFilters || [])
  },[contentTypes])

  const [searchTermInputText, setSearchTermInputText] = useState(
    sessionData?.searchTerm || ""
  );
  const [_searchTermInputValue, setSearchTermInputValue] = useState(
    sessionData?.searchTerm || ""
  );
  // in some cases we allow the parent to define its own searchinput ui, and control the searchterminputvalue from there. eg LinkOrEmbedModalLex
  const searchTermInputValue = searchTermInputValueFromParent || _searchTermInputValue;

  const [searchInUse, setSearchInUse] = useState(false);

  const [activeSort, setActiveSort] = useExtractInitBackendSortFromTableConfig({
    tableConfig: listingConfig?.tableConfig,
    enabled: listingStyle === "TableStyle",
    fallbackIfDisabled: sortConfig && sortConfig[0],
  });

  //update session storage
  useEffect(() => {
    const toSet = {
      searchTerm: searchTermInputValue || "",
      activeFilters: (activeFilters?.length > 0 && activeFilters) || [],
    };
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(toSet));
  }, [activeFilters, searchTermInputValue]);

  //refs
  const searchSortBarRef = useRef(null);
  const totalWidthRef = useRef(null);

  //custom hooks
  const debounce = useDebounce();
  const screenWidth = useScreenWidth();
  const isDesktop = screenWidth >= getBreakPoint("md");

  const getDataQueryLookup = {
    useGetDataLazy,
    useGetAllUsersLazy
  }

  const {
    error:contentError,
    data: contentData,
    status: contentStatus,
    fetchNextPage: contentFetchNextPage,
    hasNextPage,
    isFetchingNextPage: contentIsFetchingNextPage,
  } = getDataQueryLookup[getDataQuery](
    {
      activeFilters: [...presetActiveFilters, ...activeFilters], //presetActiveFilters are injected here, so that, even if filterModule 'clears' activeFilters, the presetFilters aren't cleared
      contentTypes,
      searchTerm: searchTermInputValue,
      activeSort: activeSort && activeSort.sort,
      ...getDataConfig,
      findQuery: searchTermInputValue ? {} : findQuery,
      collectionId,
      // enabled: keywordSearchConfig ? false : true,
      ksConfig:
        searchTermInputValue &&
        buildListingPageQueryForKS({
          keyword: searchTermInputValue,
          config: { fields: keywordSearchConfig.fields },
          activeSort: activeSort?.sort,
        }),
      /**
       * collectionId is only relevant in order to give a unique query key (react query)
       * to the related content that might be fetched against a given collection page
       */
    },
    {
      onSuccess: () => {},
      onError: (err) => {},
    }
  );


  
  const {
    refetch:csvRefetch
  } = useGetExportToCsvContent(
    {
      activeFilters: [...presetActiveFilters, ...activeFilters], //presetActiveFilters are injected here, so that, even if filterModule 'clears' activeFilters, the presetFilters aren't cleared
      resourceType:contentTypes,
      ...getDataConfig,
      findQuery: searchTermInputValue ? {} : findQuery,
      populateTagsArray: contentTypes && __GetContentTypeConfigNew(contentTypes[0])?.general.csvExport?.populateTagsArray ,
      enableUpdateExport:contentTypes && __GetContentTypeConfigNew(contentTypes[0])?.general.csvExport?.enableUpdateExport
      
      
    }
  );

  
  const totalCount = contentData?.pages[0]?.count
  useEffect(() => {
    if(totalCount !== undefined && broadcastTotalCount){
      broadcastTotalCount(totalCount)
    }
  },[totalCount])

  const handleSearch = (id, value) => {
    setSearchTermInputText(value);
    debounce(() => {
      trackEventGA("Search Flow","Listing Searched")
      setSearchTermInputValue(value)
    });
  };

  const genFilterModule = () => {
    if (!filters || filters.length === 0) return null;
    if (!valueNotFalsyOrEmptyAryObj(filters)) return null;
    return (
      <FilterModule
        totalResult={totalCount}
        orientation={filterModuleOrientation}
        setActiveFilters={setActiveFilters}
        activeFilters={activeFilters}
        presetActiveFilters={presetActiveFilters}
        filterConfig={filters}
        contentTypes={contentTypes}
        headerRef={searchSortBarRef}
        usedIn={"ListingPage"}
      />
    );
  };

  const showSortDropdown = sortConfig && listingStyle !== "TableStyle";
  const showSearchInput = _showSearchInput && !!keywordSearchConfig === true && !keywordSearchConfig.disabled
  const filterOrSortOrSearchConfExists = () => {
    return (
      valueNotFalsyOrEmptyAryObj(filters) ||
      showSortDropdown ||
      showSearchInput
    );
  };

   if(contentError)
   {   
     return (<ErrorStates errorResponseObject={contentError}/>)
   }
  return (
    <div>
      {coverConfig && (
        <PageCover
          coverHeadline={coverConfig.title}
          coverSubheadline={coverConfig.desc}
          btn={coverConfig.btn}
        />
      )}
      {filterOrSortOrSearchConfExists() && (
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 10, //must be evaluated in more detail later. we need to ensure this stays above all other elements on the page except for things like modals and such
            backgroundColor: white,
          }}
          ref={searchSortBarRef}
        >
          <Section
            borderBottom
            style={{
              overflowX: !isDesktop && searchInUse ? "hidden" : "unset",
            }}
          >
            <Container>
              <div ref={totalWidthRef}>
                <Wrapper1500 left>
                  <StyledContentDiscoveryWrapper>
                    {showSearchInput && (
                      <KeywordSearch
                        setSearchInUse={setSearchInUse}
                        searchTermInputText={searchTermInputText}
                        handleSearch={handleSearch}
                        totalWidthAvailable={
                          totalWidthRef.current?.getBoundingClientRect().width
                        }
                      />
                    )}
                    <StyledFilterSortWrapper
                      searchInUse={!isDesktop && searchInUse}
                    >
                      {!isDesktop && genFilterModule()}
                      {showSortDropdown && (
                        <Sort
                          {...{
                            sortConfig,
                            activeSort,
                            setActiveSort,
                          }}
                        />
                      )}
                    </StyledFilterSortWrapper>
                  </StyledContentDiscoveryWrapper>
                </Wrapper1500>
              </div>
            </Container>
          </Section>
          {filters && (
            <div>
              {isDesktop &&
                filterModuleOrientation === "topbar" &&
                filters &&
                genFilterModule()}
            </div>
          )}
        </div>
      )}
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `${
              isDesktop &&
              filterModuleOrientation === "sidebar" &&
              filters
                ? "auto"
                : ""
            } 1fr`,
          }}
        >
          {isDesktop &&
            filterModuleOrientation === "sidebar" &&
            genFilterModule()}
          <div style={{ minWidth: 0 }}>
            <ListingModule
              status={contentStatus}
              data={contentData}
              emptyPageConfigSearch={ 
                searchTermInputValue && searchTermInputValue !== "" ? // Check if searchTermInputValue is truthy
              {
                emptyStatePreset: "searchListing",
                contentTypes: contentTypes,
                searchTerm: _searchTermInputValue,
                onClick: () => {
                  setSearchTermInputText("");
                  debounce(() => setSearchTermInputValue(""));
                },
              }
              : undefined // Pass undefined when searchTermInputValue is falsy
            }
              sectionTitle={sectionTitle}
              sectionLink={sectionLink}
              totalCount={showCount ? totalCount : undefined}
              listingStyle={listingStyle}
              listingConfig={listingConfig}
              fetchNextPage={enableLazyLoad ? contentFetchNextPage : () => {}} //fetchNextPage could be undefined if lazy load is disabled in listing page. so we make it a empty function as a fallback to avoid errors
              isFetchingNextPage={contentIsFetchingNextPage}
              activeSort={activeSort}
              setActiveSort={setActiveSort}
              hasNextPage={hasNextPage}
              ListingWrapper={ListingWrapper}
              enableContainerQuery={enableContainerQuery}
              contentTypes={contentTypes}
              inTCI={inTCI}
              builderProps={builderProps}
              csvRefetch={csvRefetch}
              hasAccessToDowloadCsv={listingExport && checkAccessToDownloadCsv({resourceType:contentTypes[0]})}
              tagCategory={tagCategory}
              tag={tag}
              searchSortBarRef={searchSortBarRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingPage;
