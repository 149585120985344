import {
  CardContent,
  CardProfile,
  Container,
  ListProfile,
  SkeletonLoader,
  Wrapper1500,
  getBreakPoint,
  makeArrayFromLength,
  useScreenWidth,
  useContainerQuery,
  WrapperCardGrid
} from "oolib";
import React from "react";
import { TotalCount } from "../../../TotalCount";
import { ListingSectionTitle } from "../../../ListingSectionTitle";
import { listingWrapperSelector } from "../../utils/listingWrapperSelector";

const FallbackWrapper = ({ children, enableContainerQuery }) => (
  <Container enableContainerQuery={enableContainerQuery}>
    <Wrapper1500 left>{children}</Wrapper1500>
  </Container>
);

export const UserProfilesStyle = ({
  data,
  status,
  isFetchingNextPage,
  observerRef,
  totalCount,
  sectionTitle,
  sectionLink,
  configs,
  ListingWrapper,
  onClick,
  enableContainerQuery,
}) => {
  const Wrapper = listingWrapperSelector({
    ListingWrapper,
    FallbackWrapper,
  });

  const screenWidth = useScreenWidth();

  const genSkeletonCards = () =>
    makeArrayFromLength(6).map(() => (
      <SkeletonLoader
        style={{
          height: "20rem",
        }}
      />
    ));

  const { ref: containerRef, containerWidth } = useContainerQuery({enabled: enableContainerQuery});

  const genProfileCards = (data) =>
    data?.map((d, index) => {
      let profileType = d.profileType;
      let cardLinkUrl = `/profile/userProfiles/${profileType}/${d._id}`;
      let DynamicProfileCard = enableContainerQuery && containerWidth <= getBreakPoint('sm') 
            ? ListProfile 
            : screenWidth >= getBreakPoint('sm') 
						? CardProfile  
						: ListProfile
      return (
        <div key={d._id} ref={data.length === index + 1 ? observerRef : null}>
          <DynamicProfileCard
            id={d._id}
            data={d}
            to={cardLinkUrl}
            onClick={onClick}
            config={configs}
          />
        </div>
      );
    });

  return (
  <div ref={containerRef}>
    <Wrapper enableContainerQuery={enableContainerQuery}>
      {totalCount !== undefined && (
        <TotalCount style={{ paddingBottom: "2rem" }} totalCount={totalCount} />
      )}
      {(sectionTitle || sectionLink) && (
        <ListingSectionTitle
          sectionTitle={sectionTitle}
          sectionLink={sectionLink}
        />
      )}
      <WrapperCardGrid enableContainerQuery={enableContainerQuery}>
        {status === "loading" ? genSkeletonCards() : genProfileCards(data)}
        {isFetchingNextPage && genSkeletonCards()}
      </WrapperCardGrid>
    </Wrapper>
  </div>
  );
};
