
import axios from 'axios'
import { customAlphabet } from 'nanoid'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { queryClient } from '../../../index'
import { getRedirectUrl } from '../../../pageTpls/ContributeTpl/utils'
import { UIContent } from '../../../UIContent'
import { isDeviceOnline } from '../../environmentConfig'


import { useGetQueryData } from '../general'
import { useAppSettingsContext } from '../../../contexts/appSettingsContext'
import { useBannerContext } from 'oolib'



const getContribution = async ({
  contentType,
  contentId,
  mode,
  activeLang,
}) => {
  try {
    const url = mode === "view"
      ? `/api/content/viewContent/${contentType}/${contentId}`
      : `/api/content/getContentForEdit/${contentType}/${contentId}`;
    


    let res;
    if (mode === "view") {
      res = await axios.get(url, { params: { activeLang } });
    } else {
      res = await axios.patch(url, { activeLang });
    }

    return { ...res.data };
  } catch (error) {
    throw error;
  }
};


export const useGetContribution = ({
  contentType,
  contentId,
  mode,
  enabled
}) => {
  const { APP_SETTINGS } = useAppSettingsContext();
  const activeLang = mode==="view"? APP_SETTINGS.lang.value:undefined
  return useQuery(
    ["Contributions", mode, contentType, contentId, activeLang].filter((d)=>d!==undefined), // important
    () =>
      getContribution({
        contentType,
        contentId,
        mode,
        activeLang,
      }),
    {
      enabled:
        enabled !== undefined
          ? enabled
          : mode === "edit" || mode === "view"
          ? true
          : false,
    }
  );
};

export const useAutoSave = () => {
	const { SET_ALERT_BANNER } = useBannerContext()
  

  return useMutation(
    ({ Contribution, ACTION }) => {
			!Contribution.main && (Contribution.main = {})
			const config = { headers: { 'Content-Type': 'application/json' } }

      return axios.post(
				`/api/contributions${ACTION ? `?userAction=${ACTION}` : ''}`,
        { Contribution },
				config,
			)
    },
    {
      onSuccess: (res) => {
				if (res.data.kp_published_status === 'underModeration') {
          queryClient.setQueryData(
            [
							'ContributionCloneForModeration',
							'moderate',
              res.data.meta.kp_content_type,
              res.data.kp_pre_mod_doc,
            ],
						(prev) => ({ ...prev, contribution: res.data }),
					)
         

				} else if (res.data.kp_published_status === 'editPublished') {
          queryClient.setQueryData(
            [
							'Contributions',
							'edit',
              res.data.meta.kp_content_type,
              res.data.kp_og_published_doc,
            ],
						(prev) => ({ ...prev, contribution: res.data }),
					)
				} else if (res.data.kp_published_status === 'draft') {
          queryClient.setQueryData(
            [
							'Contributions',
							'edit',
              res.data.meta.kp_content_type,
              res.data._id,
            ],
						(prev) => ({ ...prev, contribution: res.data }),
					)
        }

      },
      onError: () =>
        SET_ALERT_BANNER({
					color: 'red',
					msg: UIContent.reactQueryHooks_contributions.autosaveErrorMsg,
					timeOut: 3000,
				}),
		},
	)
    }


// export const useGenerateTpl = () => {
//   const userData = useGetQueryData('userData');
//   return useMutation(({ content }) => {
//     if(!isDeviceOnline()){
      
//       // let prepOfflineContent = new Promise((res, rej) => {
//       //   let contribution = {
//       //     ...content,
//       //     _id: '00'+customAlphabet('1234567890', 22)(),
//       //     kp_published_status: 'draft',
//       //     meta : {
//       //       ...content.meta,
//       //       kp_contributed_by : {_id : userData.user._id }
//       //     }
//       //   }
//       //   let offlineContent = { contribution, tpl }
//       //   res({ offlineContent })
//       // })

//       // return prepOfflineContent;

//       let offlineContent = {
//         ...content,
//         _id: '00'+customAlphabet('1234567890', 22)(),
//         kp_published_status: 'draft',
//         meta : {
//           ...content.meta,
//           kp_contributed_by : {_id : userData.user._id }
//         }
//       }

//       return axios.post(
//         `/api/contributions`,
//         { Contribution: offlineContent },
//         { headers: { 'Content-Type': 'application/json' } }
//       )

//     }else{
//       return axios.post(
//         `/api/contributions`,
//         { Contribution: content },
//         { headers: { 'Content-Type': 'application/json' } }
//       )
//     }
    
//   })
// }


export const useContributionCloneForModeration = ({
  contentType,
  contentId,
  mode,
}) => {
  return useQuery(
		['ContributionCloneForModeration', mode, contentType, contentId],
    () => {
      return axios
        .get(`/api/moderation/edit/${contentType}?id=${contentId}`)
				.then((res) => res.data)
    },
		{ enabled: navigator.onLine && mode === 'moderate' ? true : false },
	)
}

export const useModerationApprove = ()=>{
  const queryClient = useQueryClient();
  const {SET_ALERT_BANNER} = useBannerContext();
  const history = useHistory();
  return useMutation(
    ({modCloneId,contentType})=>{
      return axios.post(
        `/api/moderation2/approveByModerator/${contentType}/${modCloneId}`,
        { headers: { "Content-Type": "application/json" }}
      );
    },
    {

      onSuccess: (res) => {
        queryClient.invalidateQueries(
          [ "Contributions", 'view', res.data.publishedDoc.meta.kp_content_type, res.data.publishedDoc._id ]
        );
        history.push(getRedirectUrl.onPublish({ content: res.data.publishedDoc })); 
        //read note on lie 192-3 explaining why removeQueries
        queryClient.removeQueries(
          ['ContributionCloneForModeration', 
          'moderate', 
            res.data.publishedDoc.meta.kp_content_type,
          res.data.publishedDoc._id
        ], {exact: true})

        SET_ALERT_BANNER({
          color : 'green',
          msg : UIContent.reactQueryHooks_contributions.modApproveSuccessfulMsg,
          timeOut : 4000
        })
        queryClient.invalidateQueries("modQueue");
       
      },
      onError: () =>{
        SET_ALERT_BANNER({
          color:"red",
          msg: UIContent.reactQueryHooks_contributions.modApproveErrorMsg,
          timeOut:4000
        })
      }
      },
    
  )
    }


export const useGetModQueue = () => useQuery(
  "modQueue", 
  () => axios.get('/api/moderation/view').then(res => res.data)
  );
