import axios from "axios";
import {
  ButtonPrimary,
  Divider,
  PaddingTopBottom30,
  Section,
  colors,
  getText,
} from "oolib";
import React, { useEffect, useState } from "react";
import {
  convertToRichText,
} from "../../../../utils/getters/gettersV2";
import RichTextEditor from "../../../../components/inputs/TextEditor/RichTextEditor";
import { useMutation } from "react-query";
import { useSocketContext } from "../../../../contexts/socketContext";

export const useSendMsg = () => {
  return useMutation(({ text }) => {
    const config = { headers: { "Content-Type": "application/json" } };
    return axios.post("/api/globalAdminTools/testSockets", { text }, config);
  });
};

export const ChatInterfaceTest = () => {
  const [value, setValue] = useState(undefined);
  const [resetValue, setResetValue] = useState(undefined);

  const sendMsg = useSendMsg();

  const [chatHistory, setChatHistory] = useState([]);

  const { socket } = useSocketContext();

  useEffect(() => {
    if(socket){ //socket may not exist if it hasnt been allowed on current env. check SocketContext
      socket.on("CHATBOT_TEST_MSG", (responseText) => {
        console.log({ responseText });
        setChatHistory((prev) => [
          ...prev,
          { type: "them", value: convertToRichText(responseText) },
        ]);
      });
    }
  }, [socket]);

  // useEffect(() => {
  //       socket.on('CHATBOT_TEST_MSG', data => console.log({socketMsgFrom: 'CHATBOT_TEST_MSG', data}))
  //   },[socket])

  console.log({ chatHistory });

  const sendOurTextMessage = ({value}) => {
    sendMsg.mutate({ text: getText(value) });
    setChatHistory((prev) => [...prev, { type: "us", value }]);
    setResetValue({ blocks: [{ text: "" }], entityMap: {} });
    setResetValue(undefined);
    setValue(undefined);
  }

  return (
    <div
      style={{
        height: "calc(100vh - 11rem)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Section style={{flex: '1 0 auto'}}>
        {chatHistory.map((msg, idx) => (
          <div
            key={idx}
            style={{
              display: "flex",
              justifyContent: msg.type === "us" ? "flex-end" : "flex-start",
            }}
          >
            <RichTextEditor
              readOnly
              value={msg.value}
              typo="SANS_3"
              style={{
                padding: "2rem",
                maxWidth: "30rem",
                borderRadius: "10px",
                backgroundColor:
                  msg.type === "us" ? colors.blue : colors.greyColor15,
              }}
              variant="simple"
            />
          </div>
        ))}
      </Section>
      <div 
        style={{ position: "sticky", bottom: 0, background: colors.greyColor5, padding: '1rem 2rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', padding: '1rem', background: colors.white, flex: '1 0 auto'}}>
        <RichTextEditor
          id={"CommentsModule__inputEditor"}
          style={{
            width: '100%'
            // padding: "2rem",
            // minHeight: "5rem",
            // backgroundColor: colors.greyColor5,
          }}
          typo="SANS_3"
          placeholderColor={colors.greyColor40}
          placeholder={"Write here"}
          forceValue={resetValue}
          value={value}
          variant="simple"
          onChange={(k, v) => setValue(v)}
        />
        </div>
        <ButtonPrimary
          children="Send"
          onClick={() => {
            sendOurTextMessage({value})
          }}
        />
        
      </div>
    </div>
  );
};
