import { useLocation } from "react-router-dom";
import { useQueryClient } from 'react-query';
import { useEffect, useState } from "react";
import { coachMarkConfig } from "./config";
import { useGetQueryData } from "../../utils/react-query-hooks/general";
import { getTenant } from "../../TENANT_CONFIGS";
import { getBreakPoint, useScreenWidth } from "oolib";
const useIsAnyQueryLoading = () => {

    const queryClient = useQueryClient();
    const queryCache = queryClient.getQueryCache()
    const allQueries = queryCache.getAll()
    // Check if any of the query is still loading on the platform
    const anyQueryLoading = allQueries.map((query) => query.state.status === "loading").includes(true)
    
    return anyQueryLoading
}


export const useGetCoachmarkConfig = () => {
    const [ config, setConfig ] = useState([]);
    const [ dynamicElement, setDynamicElement ] = useState(undefined) 
    const tenant = getTenant();  
    const userData = useGetQueryData("userData");

    const screenWidth = useScreenWidth()
    // screenWidth < getBreakPoint("lg")
    let dbUsercoachmarkChecklist = userData?.user?.coachmarkChecklist

    const completedCoachmarks = dbUsercoachmarkChecklist 
      ? Object.entries(dbUsercoachmarkChecklist)?.filter(([key, value]) => value === true).map(([key]) => key) // extract all the visited coachmark from user's coachmarkChecklist
      : [];
  
    const location = useLocation();
    const [ locationPath, setLocationPath ] = useState()
    let configsFoundOnCurrentPage = [];

    const getConfigsOnCurrentPage = () => {
        return coachMarkConfig[tenant]?.filter((d) => {
            const coachMarkURL = d?.coachMarkURL;
            if (coachMarkURL === '/') {
                return location.pathname === '/';
            } else {
                return location.pathname.startsWith(coachMarkURL);
            }
        }); 

    }

    configsFoundOnCurrentPage = getConfigsOnCurrentPage();
    const dynamicConfig = coachMarkConfig[tenant]?.filter(d => d?.coachMarkURL === "global")[0]?.elements
    const unvisitedDynamicConfig = dynamicConfig?.map((item) => dbUsercoachmarkChecklist && !Object.keys(dbUsercoachmarkChecklist).includes(item.key) && item ).filter(Boolean)

    useEffect(() => { setLocationPath(location.pathname + location.search) }, [location.pathname, location.search]);

    useEffect(() => {
        const configsFoundOnCurrentPage = getConfigsOnCurrentPage();

        const coachmarkskOnSideBar = configsFoundOnCurrentPage[0]?.elements?.filter((d) => d.onSidebar).filter(Boolean) 
        const coachmarkskNotOnSideBar = configsFoundOnCurrentPage[0]?.elements?.filter((d) => !d.onSidebar).filter(Boolean)
        if (configsFoundOnCurrentPage?.length > 0) {
            const config_ = configsFoundOnCurrentPage[0]?.elements.length > 0 ? screenWidth < getBreakPoint("lg") ? locationPath?.includes("menu=true") ? coachmarkskOnSideBar : coachmarkskNotOnSideBar : configsFoundOnCurrentPage[0]?.elements : [];
            setConfig(config_.filter(cm => !completedCoachmarks?.includes(cm?.key)));
        } else {
            setConfig(null);
        }
    }, [locationPath]);

    useEffect(() => {
      if (!dynamicConfig?.length) return;
    
      let observer;
      let isObserving = false;
    
      const eventChangeObserver = () => {
        if (isObserving) return;
    
        observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.attributeName === 'class') {
              const element = mutation.target;

              // newDisplayValue is the value of the display property after the mutation
              const newDisplayValue = window.getComputedStyle(element).getPropertyValue('display');
              if (newDisplayValue === 'flex') {
                // Add the element to the list of dynamic elements if it's not already there
                const item = dynamicConfig.find(config => config.id === element.id);
                const thisCMAlreadyVisited = Object.keys(dbUsercoachmarkChecklist)?.includes(item?.key);
    
                if (item && !thisCMAlreadyVisited) {
                  // After DynamicElement is set, there is a useEffect inside index.js that checks if their is a dynamicElement and shows coachmark for it and updates the db 
                  setDynamicElement([{ ...item, element }]);
                }
              }
              
              if (newDisplayValue === 'none') {
                setDynamicElement(undefined);
              }
            }
          });
        });
    
        observer.observe(document.body, {
          childList: true,
          subtree: true,
          attributes: true,
          attributeOldValue: true,
        });
    
        isObserving = true;
      };
    
      if (userData?.user && (unvisitedDynamicConfig.length > 0 || dynamicElement)) {
        // There's a logged-in user, AND
        // There are unvisited coachmarks OR there's currently an active coachmark (dynamicElement)
        eventChangeObserver();
      }
    
      return () => {
        if (observer) {
          observer.disconnect();
          isObserving = false;
        }
      };
    }, [dynamicConfig, dbUsercoachmarkChecklist, userData?.user, unvisitedDynamicConfig, dynamicElement]);

    const anyQueryLoading = useIsAnyQueryLoading()

    if(anyQueryLoading && config?.length < 0 ) return null

    return { config, dynamicElement, setDynamicElement, locationPath }
}

