import React, { useEffect, useRef, useState } from 'react'
import { StyledAnimatedCaret, StyledActionBar, StyledActionBlock, StyledShellContainer, StyledContentSection, StyledFilterButton, StyledFilterButtonWrapper, StyledFilterMobileButton, StyledMainContent, StyledMobileActions, StyledSection, StyledSideBar, StyledTitleSection, StyledTitlte, StyledFilterHeading } from './styled'
import { ButtonPrimary, Container, getBreakPoint, icons, TabBarStyle1, UI_BODY, UI_BODY_BOLD, UI_HEADLINE, UI_PARAGRAPH, UI_TITLE_SM, useScreenWidth, Wrapper1000, WrapperCardGrid } from 'oolib'
import { AnimatePresence, motion } from 'framer-motion'
import { useDebounce } from '../../../../../../utils/customHooks'
import KeywordSearch from '../../../../../../components/discovery/KeywordSearch'

const wrappers = {
  center : Wrapper1000,
  left: Container, 
} 

const { CaretLeft, Funnel } = icons;

const SIDEBAR_WIDTH = 300;

export const Shell = ({ title, CTAtext, tabBarConfig, search, sort, filter, desc, contentConfig, table }) => {
  const { tabBarOptions, tabBarOnChange, tabBarSelected } = tabBarConfig
  const [ showSideBarLeft, setShowSideBarLeft ] = useState(false)
  
  //  search 
  const [ searchTermInputText, setSearchTermInputText] = useState("");
  const [ searchTermInputValue, setSearchTermInputValue] = useState("");
  const [ searchInUse, setSearchInUse ] = useState(false);

  const debounce = useDebounce();
  
  const totalWidthRef = useRef(null);
  
  const headerRef = useRef()
  const actionRef = useRef()
  const mobileActionRef = useRef()
  
  const screenWidth = useScreenWidth()
  const [ titleSectionHeight, setTitleSectionHeight ] = useState(undefined);
  const [ actionBarHeight, setActionBarHeight ] = useState(undefined); 
  const [ isSticky, setIsSticky ] = useState(false);
  
  const isMobile = screenWidth < getBreakPoint('md')

  const handleSearch = (id, value) => {
    setSearchTermInputText(value);
    debounce(() => {
      setSearchTermInputValue(value);
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (mobileActionRef.current) {
        const offsetTop = mobileActionRef.current.getBoundingClientRect().top;
        const isAtTop = offsetTop <= 30;
        setIsSticky(isAtTop);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ screenWidth ]);

  useEffect(() => { 
    if(headerRef?.current){
      setTitleSectionHeight(headerRef?.current?.getBoundingClientRect().height )
    }
    if(actionRef?.current){
      setActionBarHeight(actionRef?.current?.getBoundingClientRect().height )
    }
  }, [ screenWidth ]);

  const ContentWrapper = wrappers[contentConfig.layout] || Wrapper1000

  return (
    <StyledShellContainer>
        
        {/* Title Section */}
        <StyledTitleSection ref={headerRef} isMobile={isMobile}> 
            <StyledTitlte>
                <UI_HEADLINE>{title || "Title"}</UI_HEADLINE>
                <ButtonPrimary icon='Plus'>{CTAtext || "Publish"}</ButtonPrimary>
            </StyledTitlte>

            {desc && <UI_PARAGRAPH>{desc || "Description"}</UI_PARAGRAPH>}
        </StyledTitleSection>

        {/* Filter button, tabbar, search, sort panel  */}
        <StyledActionBar ref={actionRef} titleSectionHeight={titleSectionHeight} isMobile={isMobile}>
              {(filter && !isMobile) && 
                  <StyledFilterButtonWrapper
                    animate={showSideBarLeft ? 'open' : 'closed'}
                    initial={false}
                    variants={filterVariants}
                  >
                      <StyledFilterButton onClick={() => setShowSideBarLeft(!showSideBarLeft)}>
                          <UI_BODY_BOLD bold>Filter</UI_BODY_BOLD>
                          <StyledAnimatedCaret
                            size={24}
                            weight={'bold'}
                            animate={{ rotate: showSideBarLeft ? 180 : 0 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 20 }}
                          />
                      </StyledFilterButton>
                  </StyledFilterButtonWrapper>
              }

              {/* Action Block Desktop version */}
              <StyledActionBlock sidebar={showSideBarLeft} filter={filter} sidebarWidth={SIDEBAR_WIDTH}>
                
                {tabBarOptions && <TabBarStyle1 options={tabBarOptions} onChange={(id, v) => tabBarOnChange(v)} value={tabBarSelected} black/>}
                {/* Search and sort component  */}
                {(search && !isMobile) && <UI_BODY style={{ width: '150px', borderRight: '1px solid #E9E9E9', padding: '1rem' }}>Search</UI_BODY>}
                {(sort && !isMobile) && <UI_BODY style={{ width: '150px', borderRight: '1px solid #E9E9E9', padding: '1rem' }}>Sort</UI_BODY>}
              </StyledActionBlock>

        </StyledActionBar>

        {/* Action Block mobile version */}
        {isMobile &&
          <StyledMobileActions isMobile={isMobile} isSticky={isSticky} ref={mobileActionRef}>
          {filter && <StyledFilterMobileButton onClick={() => setShowSideBarLeft(!showSideBarLeft)}>
                        <Funnel size={20} weight={'bold'}/>
                        <UI_BODY_BOLD style={{ marginLeft: '1rem' }}>Filter</UI_BODY_BOLD>
                     </StyledFilterMobileButton>}
            
            {/* Search and sort */}
            <div ref={totalWidthRef} style={{ flexGrow: 1 }}>
                <div style={{ display: 'flex', flexGrow: searchInUse && 1, justifyContent: 'flex-end', alignItems: 'center' , position: 'relative' }} >
                  {(search) && <KeywordSearch
                      setSearchInUse={setSearchInUse}
                      searchTermInputText={searchTermInputText}
                      handleSearch={handleSearch}
                      totalWidthAvailable={
                        totalWidthRef.current?.getBoundingClientRect().width
                      }
                    />}
                {(sort) && <UI_BODY_BOLD style={{ width: '120px', padding: '1rem' }}>Sort</UI_BODY_BOLD>}
            </div>
          </div>
        </StyledMobileActions>}
        
        <StyledMainContent>
          
        {/* Sidebar */}
        <AnimatePresence  mode="wait" initial={false}>
          {showSideBarLeft && (
            <StyledSideBar
              {...framerSidebarPanel} 
              showSideBarLeft={showSideBarLeft}
              top={titleSectionHeight + actionBarHeight || 0} // for sticky
              isMobile={isMobile}
              sidebarWidth={SIDEBAR_WIDTH}
            > 
            {/* Filter Headline for mobile version */}
              {isMobile && (
                  <StyledFilterHeading>
                    <UI_TITLE_SM>Filters</UI_TITLE_SM>
                    <StyledFilterMobileButton onClick={() => setShowSideBarLeft(!showSideBarLeft)}>
                      <CaretLeft size={24} weight='bold'/>
                    </StyledFilterMobileButton>
                  </StyledFilterHeading>
               ) 
              }

              {/* Dummy Filter options */}
              <div style={{ padding: '20px', width: `${SIDEBAR_WIDTH}px` }}>
                <UI_BODY>Id aliqua velit cupidatat</UI_BODY>
                <UI_BODY>Id aliqua velit cupidatat</UI_BODY>
                <UI_BODY>Id aliqua velit cupidatat</UI_BODY>
                <UI_BODY>Id aliqua velit cupidatat</UI_BODY>
                <UI_BODY>Id aliqua velit cupidatat</UI_BODY>
              </div>

            </StyledSideBar>
           )}  
        </AnimatePresence>

          <StyledContentSection sidebar={showSideBarLeft}
            sidebarWidth={SIDEBAR_WIDTH}
            // style={{ width: showSideBarLeft ? `calc(100% - ${SIDEBAR_WIDTH}px)` : '100%' }}
            transition={{ duration: 0.5, ease: 'easeInOut', delay:  0.5 }}
          >
            <StyledSection sidebar={showSideBarLeft} table={table} id="styledSecion">

                <ContentWrapper style={{ width: '100%', margin: contentConfig.layout === "center" && '0', padding: 0 }}>
                    {contentConfig.content || contentConfig}
                </ContentWrapper>

            </StyledSection>

          </StyledContentSection>
        </StyledMainContent>

    </StyledShellContainer>
  )
}

const filterVariants = {
  open: { width: `${SIDEBAR_WIDTH}px`, transition: { duration: 0.5 } },
  closed: { width: '120px', transition: { duration: 0.5 } },
};

const framerSidebarPanel = {
  initial: { x: '-100%' },
  animate: { x: 0 },
  exit: { x: '-100%' },
  transition: { duration: 0.5 },
}


