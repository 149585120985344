import React, { Fragment, useState, useEffect } from "react";
import {
  ActionMenu,
  ButtonPrimary,
  BlockLabel,
  getBlockLabelProps,
  PaddingTopBottom15,
  getVal,
  sortData,
  setVal,
  makeArrayFromLength,
  PaddingTopBottom10,
  OKELink,
  SANS_3,
  icons,
} from "oolib";
import {
  EditBlockGenerator,
  ViewBlockGenerator,
} from "../../../utils/blockGenerators";

import { handleSetOtherValuePaths } from "../../../utils/general";
import { cloneDeep } from "lodash";
import { blockMapper } from "../../../utils/templating/blockMapper";
import { StyledRepeaterGroup, StyledRepeaterGroupsWrapper } from "./styled";
import { nanoid } from "nanoid";
import Draggable from "../../generalUI/Draggable";
import styled from "styled-components";
const { DotsSixVertical } = icons;

function Repeater({
  id,
  blocks,
  content: parentContent,
  value: valueProp,
  onChange,
  stackBlocks = true,
  subBlocksFormValidation: subBlocksFormValidationAry,
  readOnly,
  memo, // Edit Block Gen passes Memo to the content blocks its rendering
  sorting,
  initLength = 1, // meaning init the repeater at the defined number of repeater groups
  allowAddBlocks = true,
  repeatButtonText = "Add",
  allowDeleteBlocks = true,
  activeIdx, // must be a number. if passed. only that idx of the repeater is shown
  UIStyle = "ugly", //alt: 'minimal'
  allowDraggable = false
}) {
  const props = arguments[0];

  /**
   * this reactKey also acts as a unique one-time-set-id which we use for some stuff
   * in the TCI. so this needs to stay.
   */
  const injectReactKeyIfDoesntAlreadyExist = (valueAry) =>
    valueAry.map((content) => {
      if(typeof content === 'string') return content;
      //else it will be an object so...
      if (!content.reactKey) return { ...content, reactKey: nanoid(6) };
      else return content;
    });

  const setInitValAry = () => {
    const toReturn =
      valueProp ||
      (initLength <= 0 ? [] : makeArrayFromLength(initLength).map(() => ({})));

    //this reactkey business is required to set a unique key to each item in the
    //draggable array below, this prevents bugs.
    return injectReactKeyIfDoesntAlreadyExist(toReturn);
  };

  let [valueAry, setValueAry] = useState(setInitValAry());

  useEffect(() => {
    if (valueProp) {
      let diff;
      let dummyData = [];
      if (valueProp.length < initLength) {
        diff = initLength - valueProp.length;
        dummyData = makeArrayFromLength(diff).map((d) => ({}));
      }
      setValueAry(
        injectReactKeyIfDoesntAlreadyExist([...valueProp, ...dummyData])
      );
    } else {
      setValueAry(setInitValAry());
    }
  }, [valueProp]);

  const [sortJustHappened, setSortJustHappened] = useState(false);
  useEffect(() => {
    if (sortJustHappened) setSortJustHappened(false);
  });

  
  const handleChange = (block, value, idx) => {
    setValueAry((prev) => {
      let newValueAry = cloneDeep(prev);
      let objToSet = newValueAry[idx];
      const { valuePath, setValuePath, setValuePathFn, setValuePathArgs } =
        block;
      if (valuePath === null) {
        //meaning we want to replace the resource itself with 'value'
        //e.g when we want to store string values directly in repeater array
        objToSet = setVal(objToSet, valuePath, value);
      } else {
        if (setValuePath) {
          handleSetOtherValuePaths({
            setValuePath,
            setValuePathFn,
            setValuePathArgs,
            content: objToSet,
            value,
          });
        }

        objToSet = setVal(objToSet, valuePath, value);
      }
      let mergeAnnoArgsFromRepeater;
      if(block.props?.annotation?.enable && block.comp === 'LexicalTextEditor'){
        mergeAnnoArgsFromRepeater = {
          blockComp: block.comp,
          newLexValue: value,
          idxAndValuePath: `${idx}.${valuePath}`
        }  
      }

      newValueAry[idx] = objToSet;

      onChange(id, newValueAry, mergeAnnoArgsFromRepeater ? { mergeAnnoArgsFromRepeater } : undefined);
      return newValueAry;
    });
  };

  const handleSort = () => {
    setValueAry((prev) => {
      let newValueAry = cloneDeep(prev);
      newValueAry = sortData({
        data: newValueAry,
        path: sorting.path,
        fn: sorting.fn || "alphabetical",
        sortBy: sorting.sortBy || "a",
      });
      onChange && onChange(id, newValueAry);
      return newValueAry;
    });

    /**
     * we use this value to turn off memoization momentarily when sort happens,
     * else the tagObjIdx used inside the handleChange function gets all confused,
     * since the content block is memoized, and js closures hence dont allow it to receive the
     * new index after sort.
     *
     * So when sort happens:
     * - we turn off memo, so that all the blocks rerender once again
     * - and the minute the blocks are done rerendering, we turn on memoization again ( using useEffect )
     */
    setSortJustHappened(true);
  };

  const genBlocks = (content, idx) => {
    return (
      <Fragment>
        {blocks &&
          blockMapper({
            content,
            activeSpace: { valuePath: "blocks" },
            tplConfig: { blocks }, // this is a hack, cuz blockMapper needs a valuePath in activeSpace
            blockGenerator: ({ block, blockPath }) => {
              if (readOnly) {
                return (
                  <ViewBlockGenerator
                    key={block.valuePath || JSON.stringify(block)}
                    Wrapper={
                      UIStyle === "ugly"
                        ? PaddingTopBottom15
                        : PaddingTopBottom10
                    }
                    block={block}
                    content={content}
                    parentContent={parentContent}
                  />
                );
              } else {
                return (
                  <EditBlockGenerator
                    key={block.valuePath || JSON.stringify(block)}
                    Wrapper={
                      UIStyle === "ugly"
                        ? PaddingTopBottom15
                        : PaddingTopBottom10
                    }
                    block={block}
                    content={content}
                    parentContent={parentContent}
                    memo={sortJustHappened ? false : memo}
                    onChange={(block, value) => handleChange(block, value, idx)}
                    formValidation={
                      subBlocksFormValidationAry &&
                      subBlocksFormValidationAry[idx]
                    }
                  />
                );
              }
            },
          })}
      </Fragment>
    );
  };
  const handleRepeatButtonClick = () => {
    setValueAry((prev) => {
      let newAry = [...prev, {}];
      onChange(id, newAry);
      return newAry;
    });
  };

  const genThisRepeaterBlockActionMenu = ({ idx, props = {} }) => (
    <ActionMenu
      {...props}
      actions={[
        {
          display: "Delete",
          onClick: () => {
            setValueAry((prev) => {
              let newAry = [...prev];
              newAry.splice(idx, 1);
              onChange(id, newAry);
              return newAry;
            });
          },
        },
      ]}
      align="right"
    />
  );

  const StyledDragHandleWrapper = styled.div`
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const genDragHandle = () => (
    <div style={{ position: "absolute", top: 0, left: 0 }}>
      <StyledDragHandleWrapper>
        <DotsSixVertical size={16} />
      </StyledDragHandleWrapper>
    </div>
  );
  return (
    <div>
      <BlockLabel {...getBlockLabelProps(props)} />
      {sorting?.enabled && !readOnly && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "0.5rem",
          }}
        >
          <ButtonPrimary
            S
            onClick={handleSort}
            disabled={
              valueAry.filter((d) => getVal(d, sorting.path)).length < 2
            } /////
            value={sorting.buttonDisplay || "Sort"}
          />
        </div>
      )}
      {UIStyle === "ugly" ? (
        <StyledRepeaterGroupsWrapper>
          <Draggable
            ary={valueAry}
            idValPath={"reactKey" || null} //this is to handle cases where valueAry contains string not objects. null allows us to access that string directly
            handleReposition={(newAry) => {
              setValueAry(newAry);
              onChange(id, newAry);
            }}
            renderChildren={(content, idx) => {
              return (
                <StyledRepeaterGroup
                  
                key={typeof content === 'string' ? idx : content.reactKey} //we have to set the key to idx if content is string, but this was cause bugs with draggability
                  style={{
                    position: "relative",
                    ...(activeIdx !== undefined && activeIdx !== idx
                      ? { display: "none" }
                      : stackBlocks
                      ? { display: "block" }
                      : { display: "flex", flexWrap: "wrap" }),
                  }}
                >
                  {!readOnly && genDragHandle()}
                  {!readOnly && allowDeleteBlocks && (
                    <div style={{ position: "absolute", top: 0, right: 0 }}>
                      {genThisRepeaterBlockActionMenu({ idx })}
                    </div>
                  )}
                  {genBlocks(content, idx)}
                </StyledRepeaterGroup>
              );
            }}
          />
          {/* {valueAry.map()} */}
        </StyledRepeaterGroupsWrapper>
      ) : (
        <div>
          {valueAry.map((content, idx) => (
            <div
              key={typeof content === 'string' ? idx : content.reactKey} //we have to set the key to idx if content is string, but this was cause bugs with draggability
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <div style={{ flexGrow: 1 }}>{genBlocks(content, idx)}</div>
              {!readOnly && genDragHandle()}
              {!readOnly && allowDeleteBlocks && (
                <div style={{ flexShrink: 0, paddingBottom: "1rem" }}>
                  {genThisRepeaterBlockActionMenu({ idx, props: { M: true } })}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {!readOnly &&
        allowAddBlocks &&
        (UIStyle === "ugly" ? (
          <ButtonPrimary
            icon="Plus"
            S
            style={{ margin: "1rem auto 0 auto" }}
            onClick={handleRepeatButtonClick}
          />
        ) : (
          <SANS_3 style={{ paddingTop: "1rem" }}>
            <OKELink icon="Plus" onClick={handleRepeatButtonClick}>
              {repeatButtonText}
            </OKELink>
          </SANS_3>
        ))}
    </div>
  );
}

export default Repeater;
