import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

export const useGetTpl = (tplId, options = {}) => {
	/**
	 * tplId is called kp_content_type in the tpl config.
	 * should rename eventually to tplId
	 */

	return useQuery(
		['tpl', tplId],
		() => axios.get(`/api/tpl/getTpl/${tplId}`).then((res) => res.data[0]),
		{
			enabled: options.enabled,
		},
	)
}

export const useGetTplsAndCacheSeparately = () => {
  const queryClient = useQueryClient();

	return useQuery(
		['allTpls'],
		() => axios.get(`/api/tpl/getAllTpls`).then((res) => res.data),
    {
      onSuccess: (res) => {
        res.forEach(tpl => {
          queryClient.setQueryData(
            ['tpl', tpl.kp_content_type],
            tpl
          )
        })
      }
    }
	)
}