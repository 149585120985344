import React, { useState } from "react";
import { imageUploader } from "../../../utils/general";
import {
  ButtonPrimary,
  SANS_3,
  SkeletonLoader,
  colors,
  getText,
  SimpleTable,
} from "oolib";
import uuid from "uuid";
import axios from "axios";
const { greyColor5 } = colors;

const getTimeRangeInUTC = (dateString) => {
  if (!dateString) return {};
  const dateObj = new Date(dateString);

  const date1 = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate() - 7
  );

  const date2 = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate() + 7
  );

  return { fromTime: date1, toTime: date2 };
};

function dmsToDecimal(degrees, minutes, seconds, direction) {
  const sign = direction === "S" || direction === "W" ? -1 : 1;
  return sign * (degrees + minutes / 60 + seconds / 3600);
}

function convertCoordinates(inputArr) {
  const result = [];
  for (let i = 0; i < inputArr.length; i++) {
    const inputStr = inputArr[i];
    if (inputStr.includes("°")) {
      const parts = inputStr.split(" ");
      const degrees = parseFloat(parts[0]);
      const minutes = parseFloat(parts[1]);
      const seconds = parseFloat(parts[2]);
      const direction = parseFloat(parts[3]);

      result.push(dmsToDecimal(degrees, minutes, seconds, direction || "N"));
    } else {
      result.push(parseFloat(inputStr));
    }
  }
  return result;
}

function calculateBoundingBox(lat, long, r) {
  const latitude = parseFloat(lat);
  const longitude = parseFloat(long);
  const radius = parseFloat(r);

  const R = 6371; // Earth's radius in kilometers

  // minimum and maximum latitude
  const latMin = latitude - (radius / R) * (180 / Math.PI);
  const latMax = latitude + (radius / R) * (180 / Math.PI);

  // minimum and maximum longitude
  const lonMin =
    longitude -
    ((radius / R) * (180 / Math.PI)) / Math.cos((latitude * Math.PI) / 180);
  const lonMax =
    longitude +
    ((radius / R) * (180 / Math.PI)) / Math.cos((latitude * Math.PI) / 180);

  // In most standard GIS (Geographic Information System) applications and practices,
  // the correct order for a bounding box (bounding rectangle) is usually represented as [minLongitude, minLatitude, maxLongitude, maxLatitude].

  //  return [-11.8, 20.85, -10.9, 21.35]

  // console.log({ lonMax, lonMin, latMax, latMin, latitude, longitude });
  return [lonMin, latMin, lonMax, latMax];
}

const getBoundingBox = (coordinates, radius) => {
  if (coordinates.length === 1) {
    return calculateBoundingBox(coordinates[0][0], coordinates[0][1], radius);
  }

  let minX = Number.POSITIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;

  coordinates.forEach((point) => {
    let x = point[0];
    let y = point[1];
    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  });

  return [minY, minX, maxY, maxX];
};

export default function PluginSentinel({
  id,
  label,
  sublabel,
  config = {
    colHeaderData: ["Serial Number", "Latitude", "Longitude"],
    noOfRows: 1,
  },
  onChange,
  value = { table: undefined, image: "" },
  readOnly,
  content,
}) {
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFetchMap = async () => {
    setUploadStatus("loading");

    try {
      const {
        table: coordinates,
        radius = 2,
        width = 700,
        height = 700,
      } = value;

      const extractedCoordinates = coordinates.data
        ? coordinates.data.map((d) =>
            d.cellData.slice(1, 3).map((d) => getText(d.value))
          )
        : coordinates.map((d) =>
            d.cellData.slice(1, 3).map((d) => getText(d.value))
          );

      const formatedCoordinates = extractedCoordinates.map((d) =>
        convertCoordinates(d)
      );

      const response = await axios.get("/api/pluginRoutes/ved/sentinelHUb", {
        params: {
          height,
          width,
          boundingBox: getBoundingBox(formatedCoordinates, radius),
          layerId: "TRUE-COLOR-S2L2A",
          timeRange: getTimeRangeInUTC(content.main?.dateOfObservation),
        },
      });

      //convert back to blob binary
      const blobData = response.data.data;
      const uint8Array = new Uint8Array(blobData);
      const binaryBlob = new Blob([uint8Array]);

      const upload = new FormData();

      upload.append("blob", binaryBlob);
      upload.append("folderName", "sentinel");

      upload.append("originalFilename", uuid.v4());
      upload.append("allowedFormats", []);

      imageUploader(upload, { type: "images" }, (status, data) => {
        if (status === "success") {
          onChange(id, { ...value, image: data[0].publicUrl });
          setUploadStatus("");
        } else {
          setUploadStatus("error");
        }
      });
    } catch (error) {
      console.log({ error });
      setUploadStatus("error");
    }
  };
  return (
    <div>
      {readOnly ? (
        <SimpleTable
          readOnly={readOnly}
          value={value.table}
          onChange={(id, table) => onChange(id, { ...value, table: table })}
          id={id}
          label={label}
          sublabel={sublabel}
          config={config}
        />
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div>
            <SimpleTable
              value={value.table}
              onChange={(id, table) => onChange(id, { ...value, table: table })}
              id={id}
              label={label}
              sublabel={sublabel}
              config={config}
            />
          </div>
          <div style={{ width: "fit-content", alignSelf: "end" }}>
            <ButtonPrimary
              disabled={uploadStatus === "loading"}
              onClick={handleFetchMap}
            >
              fetch Map
            </ButtonPrimary>
          </div>
        </div>
      )}

      {uploadStatus === "error" ? (
        <SANS_3>Error in uploading</SANS_3>
      ) : uploadStatus === "loading" ? (
        // <div style={{ padding: "2rem 0" }}>
        <SkeletonLoader
          // minWidth={"25rem"}
          style={{
            padding: "2rem 0",
            height: "36rem",
            backgroundColor: greyColor5,
          }}
        />
      ) : // </div>
      value.image ? (
        <div style={{ padding: "2rem 0", width: "100%" }}>
          <img
            style={{ width: "inherit", aspectRatio: "1/1" }}
            alt="sentinel_map"
            src={value.image}
          />
        </div>
      ) : null}
    </div>
  );
}
