import styled, { css } from 'styled-components'
import { icons } from 'oolib'
import { motion } from 'framer-motion'

const { CaretRight } = icons 

export const StyledAnimatedCaret = styled(motion(CaretRight))`
  display: inline-block;
`;

export const StyledShellContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledTitleSection = styled(motion.header)`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
  position: ${({ isMobile }) => !isMobile && 'sticky'};
  top: 0;
  background-color: white;
  z-index: 1000;
`;

export const StyledTitlte = styled.div`
  display: flex; 
  flex-direction: row; 
  width: 100%; 
  justify-content: space-between;
`

export const StyledActionBar = styled.div`
  width: 100%;
  display: flex;
  border-top: 1.5px solid #E9E9E9;
  border-bottom: 1.5px solid #E9E9E9;
  position: ${({ isMobile }) => !isMobile && 'sticky'};
  top: ${({titleSectionHeight}) => `${titleSectionHeight}px`};
  background-color: white;
  z-index: 1000;

  overflow-x: auto; 
  overflow-y: hidden;
  -ms-overflow-style: none;  
  scrollbar-width: none;  

  &::-webkit-scrollbar {
    display: none; 
  }
`;

export const StyledMobileActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  padding-top: ${({ isMobile, isSticky }) => (isMobile && isSticky) ? '50px' : 0 };
`

export const StyledActionBlock = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 0 2rem;  
  & > :first-child {
    flex-grow: 1;
  }
  width: ${({ sidebar, filter, sidebarWidth }) => sidebar ? `calc(100% - ${sidebarWidth}px)` : (filter && !sidebar) ? `calc(100% - 120px)` : '100%'};
  display: 'flex'; 
  flex-grow: 1;
`

export const StyledFilterButtonWrapper = styled(motion.div)`
  border-right: 1px solid #E9E9E9; 
  height: 100%; 
  display: flex;
`

export const StyledFilterButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 2rem;
  background: none;
  border: none;
  cursor: pointer;
`

export const StyledFilterHeading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 3rem 2rem;
  border-bottom: 1px solid #E9E9E9;

`
export const StyledFilterMobileButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    cursor: pointer;
    padding: 1rem 0rem;
    padding-right: 2rem;
`

export const StyledMainContent = styled(motion.div)`
  display: flex;
`;

export const StyledSideBar = styled(motion.aside)`
  border-right: 1.5px solid #E9E9E9;
  min-height: 70svh;
  max-height: 100svh;
  width: ${({ showSideBarLeft, sidebarWidth }) => showSideBarLeft ? `${sidebarWidth}px` : '0px'};
  z-index: 900;
  position: sticky;
  top: ${({ top }) => `${top}px`};
  overflow: hidden;

  ${({ isMobile}) => isMobile && css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2000;
      height: 100svh;
      background-color: white;
  `}
`
export const StyledContentSection = styled(motion.div)`
  height: inherit;
  padding: 2rem;
  display: flex;
  flex-grow: 1;  
  transition: width 0.5s ease-in-out;
  width: ${({ sidebar, sidebarWidth }) => sidebar ? `calc(100% - ${sidebarWidth}px)` : '100%'};
`;


export const StyledSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: ${({ sidebar, table }) => sidebar || table ? 'start' : 'center'};
  min-width: 0;
  transition: all 0.5s ease-in-out;
`

