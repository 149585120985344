import React from "react";

import AsideFilter from "./comps/AsideFilter";
import MobileFilter from "./comps/MobileFilter";
import TopbarFilter from "./comps/TopbarFilter";

import { convertToInput, convertToOutput, getFilterDisplay } from "./function";

import { getBreakPoint, useScreenWidth } from "oolib";
import { trackEventGA } from "../../../trackers/GA/trackEventGA";


/**
 * Why are presetActiveFilters being passed into FilterModules?
 *
 * these are only meant to be considered while parsing display conditions,
 * because the parent that is using this filterModule,
 * HAS the presetActiveFilters applied along with activeFilters.
 *
 * The reason why we parse the display conditions inside the FilterModule is
 * because that way it becomes a global feature rather than specific to one
 * particular module.
 */

const FilterModule = ({
  presetActiveFilters = [],
  activeFilters = [],
  setActiveFilters,
  filterConfig,
  /**
   * //OPTIONAL: about contentTypes prop
   * the reason this is passed thru, is so that the FilterInput component
   * inside Topbar/Aside/Mobile Filter knows what contenttypes are 
   * being filtered, and hence fetch only the tags that have these 
   * content types as tagged resources.
   *
   * This should not be used all the time. For example, if your filters
   * are using 'rollup', then obv this content type that is being filtered
   * wont exist as a taggedResource on the tag doc (since the rollup resource
   * will exist instead). In such cases, best not to pass this contentTypes prop only
   *
   */

  contentTypes,
  orientation = "topbar", //alt sidebar
  headerRef,
  totalResult,
  usedIn,
}) => {
  const screenWidth = useScreenWidth();

  const handleOnChange = ({ id, value, display }) => {
    const selectedFilterOptions = convertToInput(activeFilters);
    const gaCategory=getFilterDisplay({filterConfig,filterId:id})
   
   
    let updated;

    if (value.length === 0) {
      delete selectedFilterOptions[id];
      updated = selectedFilterOptions;
    } else {
      updated = { ...selectedFilterOptions, [id]: value };
      const prevValForThisFilter = selectedFilterOptions[id] || [];
      if (value.length > prevValForThisFilter.length) {
        const newlyAdded = value.find((v) =>
          prevValForThisFilter.every((vv) => vv.value !== v.value)
        );
     
        if(usedIn)
       {
        trackEventGA(`${gaCategory}`, `${usedIn} Filter`, `${newlyAdded.display}`);
        }
      }
    }

    setActiveFilters(convertToOutput({ updated, filterConfig }));
  };

  const updateActiveFilters = (removedFilterValue, clearAll = false) => {
    const allSelectedFilters = [];

    if (clearAll) {
      setActiveFilters([]);
    } else {
      for (let i = 0; i < activeFilters.length; i++) {
        const filterParam = activeFilters[i];

        const index = filterParam.values.findIndex(
          (value) => value.value === removedFilterValue
        );

        if (index !== -1) {
          if (filterParam.values.length > 1) {
            filterParam.values.splice(index, 1);
            allSelectedFilters.push(filterParam);
          }
        } else {
          allSelectedFilters.push(filterParam);
        }
      }
      setActiveFilters(allSelectedFilters);
    }
  };

  return (
    <div>
      {screenWidth >= getBreakPoint("md") ? (
        orientation === "sidebar" ? (
          <AsideFilter
            selectedValues={convertToInput(activeFilters)}
            filterConfig={filterConfig}
            onChange={handleOnChange}
            updateActiveFilters={updateActiveFilters}
            headerRef={headerRef}
            activeFilters={activeFilters}
            presetActiveFilters={presetActiveFilters}
            contentTypes={contentTypes}
          />
        ) : (
          <TopbarFilter
            selectedValues={convertToInput(activeFilters)}
            onChange={handleOnChange}
            filterConfig={filterConfig}
            updateActiveFilters={updateActiveFilters}
            activeFilters={activeFilters}
            presetActiveFilters={presetActiveFilters}
            contentTypes={contentTypes}
          />
        )
      ) : (
        <MobileFilter
          onChange={handleOnChange}
          updateActiveFilters={updateActiveFilters}
          selectedValues={convertToInput(activeFilters)}
          totalResult={totalResult === "loading" ? "..." : totalResult ?? 0}
          filterConfig={filterConfig}
          activeFilters={activeFilters}
          presetActiveFilters={presetActiveFilters}
          contentTypes={contentTypes}
        />
      )}
    </div>
  );
};

export default FilterModule;
