import {
  Accordion,
  ButtonGhost,
  CheckboxInput,
  SANS_3,
  TextLoader,
  colors,
  transition,
} from "oolib";
import React, { useState } from "react";
import styled from "styled-components";
import { BadgeNumber } from "../../generalUI/BadgeNumber";



const StyledFilterAccordionHeader = styled.div`
  display: flex;
  padding: 1rem 2rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const StyledOptionWrapper = styled.div`
  padding: 0.5rem 2rem;
  cursor: pointer;
  ${transition("background-color 0.3s")}

  @media (hover:hover) {
    &:hover {
      background-color: ${colors.greyColor5};
    }
  }
`;

export const AccordionMultiSelect = ({
  id,
  value,
  options,
  title,
  onChange,
  broadcastExpandState,
}) => {
  const [expand, setExpand] = useState(false);

  const handleOptionClick = (option) => {
    let newValue = [...value];
    let isSelected = newValue.some((d) => d.value === option.value);
    if (!isSelected) {
      newValue.push(option);
    } else {
      newValue = newValue.filter((d) => d.value !== option.value);
    }

    onChange(id, newValue);
  };

  const handleAccordionToggle = () => {
    const newExpandState = !expand;
    setExpand(newExpandState);
    broadcastExpandState && broadcastExpandState(newExpandState);
  };

  return (
    <Accordion
      expand={expand}
      CustomHeader={
        <StyledFilterAccordionHeader onClick={handleAccordionToggle}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <SANS_3 semibold>{title}</SANS_3>
            {value?.length ? <BadgeNumber number={value.length} /> : null}
          </div>
          <ButtonGhost icon={expand ? "Minus" : "Plus"} S />
        </StyledFilterAccordionHeader>
      }
    >
      {options?.map((d) => (
        <StyledOptionWrapper key={d.value} onClick={() => handleOptionClick(d)}>
          {d.loading ? (
            <TextLoader style={{ width: "8rem", height: "1.5rem" }} />
          ) : (
            <CheckboxInput option={d} value={value} />
          )}
        </StyledOptionWrapper>
      ))}
    </Accordion>
  );
};
