import { 
  Container, 
  Section, 
  Wrapper1000, 
  Wrapper700, 
  PaddingTopBottom15, 
  PaddingTopBottom45,} from "oolib";
import React from "react";
import { blockMapper } from "../../../../../../utils/templating/blockMapper";
import { GeneratorComp } from "../GeneratorComp";
import { useFormValidationContext } from "../../../../../../contexts/formValidationContext";


const DefaultFormWrapper = ({children, align = 'center'}) => {
  
  

  return (
    <Section>
        <Container style={{position: "relative"}}>
          <Wrapper1000>
            <Wrapper700 left={align === 'left'}> 
              <PaddingTopBottom45>
                {children}
              </PaddingTopBottom45>
            </Wrapper700>
          </Wrapper1000>
        </Container>
      </Section>
  )
}

export const FormConfigsRenderer = ({
  configs,
  handleOnChange,
  content,
  readOnly,
  memo,
  FormWrapper : _FormWrapper = DefaultFormWrapper,
  blockWrapper = PaddingTopBottom15,
  indexList,
  tpl
}) => {

  const {formValidation, setFormValidation} = useFormValidationContext()

  const getFormWrapper = (_FormWrapper) => {
    const FormWrappers = {
      DefaultFormWrapper
    }
    //with the below, FormWrapper prop is flexible.
    //it can either be a string, React comp or a obj of form {comp, props}
    const FormWrapper = _FormWrapper === 'div'
      ? 'div' 
      : typeof _FormWrapper === 'string' //e.g FormWrapper = 'DefaultFormWrapper'
        ? FormWrappers[_FormWrapper]
        : _FormWrapper.comp
          ? typeof _FormWrapper.comp === 'string' //e.g FormWrapper = {comp: 'DefaultFormWrapper', props: ...}
            ? FormWrappers[_FormWrapper.comp] 
            : _FormWrapper.comp //e.g FormWrapper = {comp: DefaultFormWrapper, props: ...} 
          : _FormWrapper //e.g FormWrapper = DefaultFormWrapper
  
    const FormWrapperProps = _FormWrapper.props || {}

    return {
      FormWrapper,
      FormWrapperProps
    }
  }

  const {FormWrapper, FormWrapperProps} = getFormWrapper(_FormWrapper)
  

  return (
    <FormWrapper {...FormWrapperProps}>
      {blockMapper({
        content,
        activeSpace: { valuePath: "configs" },
        tplConfig: { configs },
        blockGenerator: ({ block }) => {
          return(
          <GeneratorComp
            {...{
              key: block.props?.id,
              block,
              blockWrapper,
              readOnly,
              memo,
              handleOnChange,
              content,
              formValidation,
              setFormValidation,
              indexList,
            }}
          />
        )},
      })}
    </FormWrapper>
  );
};
