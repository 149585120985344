import { ButtonGhost, ButtonSecondary, SANS_0, SANS_2, getBreakPoint, useScreenWidth } from "oolib";
import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { StyledActions, StyledCoachMark, StyledContent, StyledHeading, StyledTooltip } from "./styled";
import { calculateTooltipPosition } from "./utils/calculateTooltipPosition";
import { useGetCoachmarkConfig } from "./useGetCoachmarkConfig";
import { useUpdateCoachmarkChecklist } from "../../utils/react-query-hooks/users";
import { useGetQueryData } from "../../utils/react-query-hooks/general";
import { getElementsFoundOnPage } from "./utils/getElementsFoundOnPage";
import { disablePageInteraction, enablePageInteraction } from "./utils/disablePageInteraction";
import { scrollElementToCenter } from "./utils/scrollElementToCenter";
import { setElementDimensions } from "./utils/setElementDimensions";
import { useQueryClient } from "react-query";
import { debounce } from "lodash";
import { usePreAccessChecklistContext } from "../../contexts/preAccessChecklistContext";

export const Coachmark = () => {
    const { config, dynamicElement, locationPath } = useGetCoachmarkConfig();
    const [ elements, setElements ] = useState([]);
    const [ activeCoachMark, setActiveCoachMark ] = useState(null);
    const [ cmIndex, setCmIndex ] = useState(0);
    const [ dimension, setDimension ] = useState(null);
    const screenWidth = useScreenWidth();
    const userData = useGetQueryData("userData");
    const updateCoachmarkList = useUpdateCoachmarkChecklist();
    let queryClient = useQueryClient();
    
    const { preAccessChecklist } = usePreAccessChecklistContext();
    
    const accessCheckListKeys = ['userAgreement', 'gaAgreement']

    const pendingPreAccessChecklist = accessCheckListKeys?.map((key, i) => preAccessChecklist?.find((d) => d.key === key && !d.complete)).filter(Boolean)?.length > 0 ? true : false
    
    useEffect(() => {
        if (config?.length < 1) return;
        getElementsFoundOnPage({setElements, config, setCmIndex, screenWidth});

    }, [config]);

    useEffect(() => {
        setActiveCoachMark(dynamicElement?.length > 0 ? dynamicElement[0] : elements?.length > 0 ? elements[cmIndex] : null);
        if(dynamicElement?.length > 0){
            setCmIndex(0)
        }
        if(pendingPreAccessChecklist) return 
        const updateDimensions = () => {
            const element = dynamicElement?.length > 0
                ? document.getElementById(dynamicElement[0]?.id)
                : document.getElementById(elements[cmIndex]?.id);
            if (element) { // if the element is found

                setElementDimensions({element, setDimension}); // get the dimensions of current element and set them to state
                disablePageInteraction();  // disable all the mouse interactions, so nothing but coachmark is able to be clicked, also need to figure out disable keyboard events
                scrollElementToCenter(element, locationPath) // scroll elemenet into view

            }else{
                enablePageInteraction(); // enable all the mouse interactions
            }
        };
    
        const debouncedUpdateDimensions = debounce(updateDimensions, 100); // debounce the updateDimensions function to prevent unnecessary re-renders
        
        updateDimensions();
    
        window.addEventListener('scroll', debouncedUpdateDimensions);
        window.addEventListener('resize', debouncedUpdateDimensions);
    
        return () => {
            window.removeEventListener('scroll', debouncedUpdateDimensions);
            window.removeEventListener('resize', debouncedUpdateDimensions);
        };
    }, [activeCoachMark, cmIndex, elements, dynamicElement]);
    
    const updateCMinDB = (key) => {
        updateCoachmarkList.mutate(
            {data: userData, property: key, value: true},
            {   
                onSuccess: () =>  {
                    queryClient.invalidateQueries('userData')
                } 
            }
        )
    }
    
    useEffect(() => {        
        if(dynamicElement?.length < 1) return;
        dynamicElement?.[0].key && updateCoachmarkList.mutate({data: userData, property: dynamicElement?.[0].key, value: true}) // the first element is always will be the active coachmark in case of dynamic element
    }, [dynamicElement?.length])

    const handleNext = (element) => {
        if (cmIndex >= elements.length - 1) {
            setElements([]); // set elements to empty array once all coachmarks are visited
            setCmIndex(0) 
            setActiveCoachMark(null)
        }
        setDimension(null)
        setCmIndex((prev) => prev + 1);
        updateCMinDB(element.key)
        
    };

    const dynamicPosition = useMemo(() => {
        if (!dimension) return null;

        if (screenWidth < getBreakPoint('xl')) {
            if (activeCoachMark?.position === 'top-right-bottom' || activeCoachMark?.position === 'bottom-right-top' || activeCoachMark?.position === "right-corner" || activeCoachMark?.position ===  "left-corner" ) {
                return activeCoachMark.position;
            } else if (dimension.top <= 300) {
                return 'bottom';
            } else if (dimension.bottom <= 300) {
                return 'top';
            }
        }
        return activeCoachMark?.position || "bottom";
    }, [screenWidth, dimension, activeCoachMark]);

    const tooltipStyle = useMemo(() => {
        return calculateTooltipPosition({ dimension, position: dynamicPosition, screenWidth });
    }, [dimension, dynamicPosition, screenWidth]);

    if (dimension?.top === 0 && dimension?.bottom === 0) return null

    const handleSkip = () => {
        setCmIndex(elements.length);
        setElements([]);
    };

    const tooltip = (
        <StyledTooltip
            style={tooltipStyle} // calculated tooltip position from calculateTooltipPosition with responsive conditions
            placement={dynamicPosition || activeCoachMark?.position || "bottom"} // placement is for tooltip arrow, used for :before of tooltip element
            >
                
            <StyledHeading>
                <SANS_2 bold>{activeCoachMark?.title}</SANS_2>
            </StyledHeading>
            <StyledContent>
                <SANS_2 style={{ background: 'none' }}>{activeCoachMark?.enableCoachmark?.text || activeCoachMark?.content}</SANS_2>
            </StyledContent>
            <StyledActions>
                <SANS_0 style={{ marginBottom: "0.5rem" }}>{dynamicElement?.length > 0 ? "" : `${cmIndex + 1} of ${elements?.length}`}</SANS_0>
                <div style={{ display: 'flex' }}>
                    <ButtonGhost S onClick={handleSkip}>Skip</ButtonGhost>
                    <ButtonSecondary S style={{ width: "max-content" }} onClick={(e) =>  handleNext(activeCoachMark) }>
                        {activeCoachMark?.enableCoachmark?.actionText || activeCoachMark?.actionText || "Okay"}
                    </ButtonSecondary>
                </div>
            </StyledActions>
        </StyledTooltip>
    );
    if( pendingPreAccessChecklist ) return null

    if (!activeCoachMark?.id || dynamicElement?.length === 0 || !userData ) return null;

    return (
        <>
            {createPortal(<StyledCoachMark dimension={dimension} />, document.body)}
            {createPortal(tooltip, document.body)}
        </>
    );
};
