import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $getTextContent,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_LOW,
  PASTE_COMMAND,
  UNDO_COMMAND
} from "lexical";
import {
  OptionsSingle,
  SANS_2,
  getBreakPoint,
  injectHttps,
  useHandleClickOutside,
  useScreenWidth,
} from "oolib";
import { mergeRegister } from "@lexical/utils";
import React, { useEffect, useRef, useState } from "react";
import { useGetEmbedTypeFromUrl } from "../ToolbarPlugin/LinkAndEmbedTools/LinkOrEmbedModalLex/utils/useGetEmbedTypeFromUrl";
import { handleInsertEmbed } from "../ToolbarPlugin/LinkAndEmbedTools/handlers/handleInsertEmbed";
import { getCursorPosRect } from "./utils/getCursorPosRect";

const getPastedText = (event) => {
  let clipboardData =
    (event instanceof InputEvent ? null : event.clipboardData) || null;
  const pastedText =
    clipboardData instanceof DataTransfer
      ? clipboardData.getData("Text")
      : undefined; //WARNING: 'Text' is a non standard property
  return pastedText;
};


const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(injectHttps(urlString)));
  } catch (e) {
    return false;
  }
};



export const LinkToEmbedPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [cursorPosRect, setCursorPosRect] = useState(undefined);
  const [showEmbed, setShowEmbed] = useState(undefined);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      
      editorState.read(() => {

          const editorHTMLNode = editor.getRootElement();
          const cursorPosRect = getCursorPosRect(
            window.getSelection(),
            editorHTMLNode
          );
          
          if (cursorPosRect) setCursorPosRect(cursorPosRect);
        
      });
    });
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        PASTE_COMMAND,
        (event) => {
          /**
           * check to see if there is no other text content in this node. 
           * only then offer to convert it into an embed
           */
          const selection = $getSelection();
          //since this would always be a collapsed selection since paste just happened
          //the nodes in this selection would be just 1. Multiple nodes can't possibly be selected.
          const currNode = selection.getNodes()[0]
          if(!currNode.getTextContent().trim()){ //no other text exists in this node (apart from the one just pasted)
            const pastedText = getPastedText(event);
            if (pastedText && isValidUrl(pastedText)) {

              setShowEmbed({ url: pastedText });
            }
          }
          return false; //returning false means it will allow other paste handlers to also fire and do their thing
        },
        COMMAND_PRIORITY_LOW //means it will fire after all other higher priority PASTE_COMMANDs have fired
      ),
      editor.registerCommand(
        UNDO_COMMAND,
        e => {
          //basically if the embed box is currently active, then on undo, it should be removed.
          if(showEmbed) setShowEmbed(undefined);
          return false
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, showEmbed]);


  //we get the embed type here itself, instead of in RTEEmbed,
  //so that we might be able to update the lightbox text in the future
  //if we need to
  const { embedType, loading: embedTypeLoading } = useGetEmbedTypeFromUrl(
    showEmbed?.url
  );


  useEffect(() => {
    /**
     * if showEmbed, and embedType has been defined means the 
     * lightbox is being rendered. So lets scroll to it 
     * if its below viewport.
     */
    if(showEmbed && embedType){ 
      if(compRef.current.getBoundingClientRect().bottom > window.innerHeight){
        compRef.current.scrollIntoView({
          behavior: "smooth",
          // block: 'nearest'
        });
      }
    }
  },[embedType, showEmbed])

  const compRef = useRef(null);
  const screenWidth = useScreenWidth();
  useHandleClickOutside(compRef, setShowEmbed, {
    enabled: screenWidth >= getBreakPoint("sm"),
  });

  const options = [
    { display: "Create Embed", value: true },
    { display: "Keep as Link", value: false },
  ];

  const handleClick = (v) => {
    if (v === false) setShowEmbed(undefined);
    else {
      editor.update(() => {
        const selection = $getSelection();
        const currNode = selection.getNodes()[0]
        currNode.remove()
      })
      handleInsertEmbed({
        editor,
        embedType,
        link: showEmbed.url,
        callback: () => {
          setShowEmbed(undefined)
        },
      });
    }
  };

  const getLightboxPosCSS = (cursorPosRect) => {
    /**PENDING: need to make this absolute position based */
    const toReturn = {
      position: "absolute",
      left: cursorPosRect.xFromEditor,
      top: cursorPosRect.bottomFromEditor,
    };
    return toReturn
  };

  if (!showEmbed) return null;

  return (
    <>
      {showEmbed && (
        <div
          ref={compRef}
          style={
            screenWidth > getBreakPoint("sm") &&
            getLightboxPosCSS(cursorPosRect)
          }
        >
          {embedTypeLoading ? (
            <SANS_2>{"Loading..."}</SANS_2>
          ) : (
            <div>
              {/* works but some css issues need sorting out. */}
              <OptionsSingle
                {...{
                  options,
                  showOptions: true,
                  handleHideOptions: () => setShowEmbed(undefined),
                  handleSelect: (op) => handleClick(op.value),
                  enableUseSetYOrientation: false,
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
