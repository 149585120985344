



const buildSortConfigForKS = (activeSort) => {
    let key = Object.keys(activeSort)[0];
    const value = Object.values(activeSort)[0];
  
    // remove all .0's
    key = key.replaceAll(".0", "");
  
  
    //special condition,  this needs to done in a more nice way
    // if key is not a date then assuming it's a text field, text fields are not optimized for sort operation ( in elastic search)
    // that's why using the sub field keyword for sorting.
    

    // assumption, if the key (sort field valuePath) contains date in it, then it's a date type field
    if (!/date/i.test(key)) {
      key += ".keyword";
    }
  
    return [
      {
        [key]: {
          order: value === -1 ? "desc" : "asc",
        },
      },
    ];
  };
  /**
   * future should use a library that parses 
   * the json into elastic search readable config
   */
  export const buildListingPageQueryForKS = ({ keyword, activeSort, config }) => {
    if (!config) return;
  
    const { fields } = config;
  
    return {
      query: {
        bool: {
          must: [
            keyword
              ? {
                  multi_match: {
                    fields,
                    query: keyword,
                    // fuzziness: "AUTO",
                    // prefix_length: 3,
                    analyzer:"standard"
                  },
                }
              : { match_all: {} },
          ],
          filter: [
            {
              term: {
                kp_published_status: "published",
              },
            },
          ],
        },
      },
      sort: activeSort ? buildSortConfigForKS(activeSort) : undefined,
      // size: 10,
    };
  };