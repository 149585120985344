import { stitchLink } from "../../../utils/general";
import {  __GetContentTypeConfigNew } from "../../../utils/getters/gettersV2";


export const getRedirectUrl = {

    onClose : ({isModerate, content}) => {
        const {meta: {kp_content_type : contentType}, kp_published_status: publishedStatus} = content;
        
        const segment = __GetContentTypeConfigNew(contentType).general?.segment
        switch(segment){
            case 'collections':
            return `/platformBuilder/tagsManager/${contentType}`
            
            case 'staticPages':
            return '/platformBuilder/staticPages'      

            case 'publishing':
            default:
            return isModerate
                ? "/my-dashboard/ModQueue?activeTab=underMyModeration"
                : publishedStatus === 'editPublished'
                    ? "/my-dashboard/MyContent?activeTab=published"
                    : "/my-dashboard/MyContent?activeTab=draft"
        }

    },

    onPublish : ({userAction, content}) => {
        const { meta: {kp_content_type: contentType}} = content
        
        const {general, listing } = __GetContentTypeConfigNew(contentType);
        const {segment, postPblRedirPath} = general || {}

        const defaultsBySegment = {
            publishing : listing?.enable ? `/published-page/${contentType}?id=${content._id}` : '/',
            collections : `/platformBuilder/tagsManager/${contentType}`,
            staticPages : `/platformBuilder/staticPages`  
        }

        if(userAction === 'SUBMIT') return `/my-dashboard/MyContent?activeTab=inModeration`
        
        //else if no config, then use defaults
        if(!postPblRedirPath) return defaultsBySegment[segment];
            
        //else postPblRedirPath is defined
        switch(true){
            case typeof postPblRedirPath === 'string':
            return postPblRedirPath;
            
            case (
                postPblRedirPath.STITCH_LINK /** back-compat */ || 
                postPblRedirPath.type === 'dynamic'
            ) :
            return stitchLink(content, postPblRedirPath) 
            
            case postPblRedirPath.type === 'static':
                return postPblRedirPath.staticUrl;

            case postPblRedirPath.type === 'default':
                return defaultsBySegment[segment];

            default:
                return;    
        }
    }

}


// returns props for ConfirmAction comp
export const getConfirmActionProps = ({
  config,
  contentType,
}) => {

  if (
    !config?.enable ||
    !config?.contentTypes ||
    !config?.contentTypes[contentType]
  ) {
    return { enabled: false };
  }

  const configObj = config.contentTypes[contentType];

  return { ...configObj, enabled: true };
};
