import {
  CheckboxButton,
  Container,
  PaddingBottom40,
  PaddingTop20,
  SANS_2,
  Wrapper700,
  setVal,
} from "oolib";
import React, { useState } from "react";

import { PaddingTopBottom40 } from "oolib";
import RichTextEditor from "../../../../components/inputs/TextEditor/RichTextEditor";
import KPRichInput from "../../../../components/inputs/KPRichInput";
import { exampleOldInlineImageValue, lexinitval } from "./configs";
import LexicalTextEditor from "../../../../components/inputs/TextEditor/LexicalTextEditor";
import { cloneDeep } from "lodash";
import { StaticRichText } from "../../../../components/inputs/StaticRichText";

export const RTETest = () => {
  // const [srte, setSrte] = useState(undefined);
  const [rteSimp, setRteSimp] = useState({
    "blocks": [
      {
        "key": "8j6ub",
        "text": "Monuwara Begum is growing weary of moving every time water pours into her home.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      },
      {
        "key": "3cqj3",
        "text": "The 45-year-old farmer, who lives on an island in the mighty Brahmaputra River in the northeastern Indian state of Assam, said she and her family suffer from more violent and erratic floods each year.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      },
      {
        "key": "alv16",
        "text": "They live in their small hut even when there is knee-deep water inside, sometimes for days – cooking, eating and sleeping – when the river water rises.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      },
      {
        "key": "7u6ll",
        "text": "When the water engulfs their home, “we leave everything and try to find some higher ground or shift to the nearest relief camp”, Begum said.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      },
      {
        "key": "aeeag",
        "text": "Begum is one of an estimated 240,000 people in the Morigaon district of the state who are dependent on fishing and selling produce like rice, jute and vegetables from their small farms on floating river islands, known locally as chars.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      }
    ],
    "entityMap": {}
  });
  const [rteOld, setRteOld] = useState(undefined);
  const [rte, setRte] = useState(
    // undefined
    // exampleOldInlineImageValue,
    // exampleAnnoValue
    {
      blocks: [
        {
          key: "8j6ub",
          text: "Begum is one of an estimated 240,000 people in the Morigaon district of the state who are dependent on fishing and selling produce like rice, jute and vegetables from their small farms on floating river islands, known locally as chars.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 9,
              length: 226,
              key: 0,
            },
          ],
          data: {},
        },
      ],
      entityMap: {
        0: {
          type: "ANNO",
          mutability: "MUTABLE",
          data: [
            {
              tags: {
                cars24: {
                  collectionId: "cars24",
                  data: [
                    {
                      _id: "650fd99987c642349c0d39f1",
                      display: "tag1",
                      tagId: "tag1",
                    },
                    {
                      _id: "650fd99987c642349c0d39f6",
                      display: "tag4",
                      tagId: "tag4",
                    },
                  ],
                },
              },
              fragmentKey: "jRR1",
            },
          ],
        },
      },
    }
  );

  const [lex, setLex] = useState({
    path1: {
      "isLexical": true,
      "editorState": {
        "root": {
          "children": [
            {
              "children": [
                {
                  "detail": 0,
                  "format": 0,
                  "mode": "normal",
                  "style": "",
                  "text": "Monuwara Begum is growing weary of moving every time water pours into her home.",
                  "type": "text",
                  "version": 1
                }
              ],
              "direction": "ltr",
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            },
            {
              "children": [
                {
                  "detail": 0,
                  "format": 0,
                  "mode": "normal",
                  "style": "",
                  "text": "The 45-year-old farmer, who lives on an island in the mighty Brahmaputra River in the northeastern Indian state of Assam, said she and her family suffer from more violent and erratic floods each year.",
                  "type": "text",
                  "version": 1
                }
              ],
              "direction": "ltr",
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            },
            {
              "children": [
                {
                  "detail": 0,
                  "format": 0,
                  "mode": "normal",
                  "style": "",
                  "text": "They live in their small hut even when there is knee-deep water inside, sometimes for days – cooking, eating and sleeping – when the river water rises.",
                  "type": "text",
                  "version": 1
                }
              ],
              "direction": "ltr",
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            },
            {
              "children": [
                {
                  "detail": 0,
                  "format": 0,
                  "mode": "normal",
                  "style": "",
                  "text": "When the water engulfs their home, “we leave everything and try to find some higher ground or shift to the nearest relief camp”, Begum said.",
                  "type": "text",
                  "version": 1
                }
              ],
              "direction": "ltr",
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            },
            {
              "children": [
                {
                  "detail": 0,
                  "format": 0,
                  "mode": "normal",
                  "style": "",
                  "text": "Begum is one of an estimated 240,000 people in the Morigaon district of the state who are dependent on fishing and selling produce like rice, jute and vegetables from their small farms on floating river islands, known locally as chars.",
                  "type": "text",
                  "version": 1
                }
              ],
              "direction": "ltr",
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            },
            {
              "children": [],
              "direction": null,
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            },
            {
              "children": [],
              "direction": null,
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            },
            {
              "type": "ImageInputNode",
              "value": [
                {
                  "kind": "storage#object",
                  "id": "dev-okf-assets-01/okft/images/image_1_492200cc-853e-401f-b126-f2af758f059f.jpeg/1696814136075118",
                  "selfLink": "https://www.googleapis.com/storage/v1/b/dev-okf-assets-01/o/okft%2Fimages%2Fimage_1_492200cc-853e-401f-b126-f2af758f059f.jpeg",
                  "mediaLink": "https://storage.googleapis.com/download/storage/v1/b/dev-okf-assets-01/o/okft%2Fimages%2Fimage_1_492200cc-853e-401f-b126-f2af758f059f.jpeg?generation=1696814136075118&alt=media",
                  "name": "okft/images/image_1_492200cc-853e-401f-b126-f2af758f059f.jpeg",
                  "bucket": "dev-okf-assets-01",
                  "generation": "1696814136075118",
                  "metageneration": "1",
                  "contentType": "image/jpeg",
                  "storageClass": "STANDARD",
                  "size": 22888,
                  "md5Hash": "JL58qYuYvICBZnnDY+Vtcw==",
                  "crc32c": "UEuiBQ==",
                  "etag": "CO7G0p7l54EDEAE=",
                  "timeCreated": "2023-10-09T01:15:36.138Z",
                  "updated": "2023-10-09T01:15:36.138Z",
                  "timeStorageClassUpdated": "2023-10-09T01:15:36.138Z",
                  "originalFilename": "image (1).png",
                  "mediaHost": "gcs",
                  "publicUrl": "https://storage.googleapis.com/dev-okf-assets-01/okft/images/image_1_492200cc-853e-401f-b126-f2af758f059f.jpeg",
                  "format": "jpeg",
                  "width": 1506,
                  "height": 376,
                  "cropX": 0,
                  "cropY": 0,
                  "caption": {
                    "isLexical": true,
                    "editorState": {
                      "root": {
                        "children": [
                          {
                            "children": [
                              {
                                "detail": 0,
                                "format": 0,
                                "mode": "normal",
                                "style": "",
                                "text": "hello",
                                "type": "text",
                                "version": 1
                              }
                            ],
                            "direction": "ltr",
                            "format": "",
                            "indent": 0,
                            "type": "paragraph",
                            "version": 1
                          }
                        ],
                        "direction": "ltr",
                        "format": "",
                        "indent": 0,
                        "type": "root",
                        "version": 1
                      }
                    }
                  }
                }
              ],
              "multiple": true,
              "id": "794QUH"
            },
            {
              "children": [],
              "direction": null,
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            },
            {
              "children": [],
              "direction": null,
              "format": "",
              "indent": 0,
              "type": "paragraph",
              "version": 1
            }
          ],
          "direction": "ltr",
          "format": "",
          "indent": 0,
          "type": "root",
          "version": 1
        }
      }
    },
    // path1: lexinitval,
    path2: undefined
    // path2: {
    //   "isLexical": true,
    //   "editorState": {
    //     "root": {
    //       "children": [
    //         {
    //           "children": [
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "Lexical is an extensible JavaScript web text-editor framework with an emphasis on reliability, accessibility, and performance. Lexical aims to provide a best-in-class developer experience, so you can easily prototype and build features with confidence. Combined with a highly extensible architecture, Lexical allows developers to create unique text editing experiences that scale in size and functionality.",
    //               "type": "text",
    //               "version": 1
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "Lexical works by attaching itself to a ",
    //               "type": "text",
    //               "version": 1
    //             },
    //             {
    //               "detail": 0,
    //               "format": 16,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "contentEditable",
    //               "type": "text",
    //               "version": 1
    //             },
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": " element and from there you can work with Lexical's declarative APIs to make things happen without needing to worry about specific edge-cases around the DOM. In fact, you rarely need to interact with the DOM at all in most cases (unless you build your own custom nodes).",
    //               "type": "text",
    //               "version": 1
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "The core package of Lexical is only 22kb in file size (min+gzip) and you only ever pay the cost for what you need. So Lexical can grow with your surface and the requirements. Furthermore, in frameworks that support lazy-loading, you can defer Lexical plugins until the user actually ",
    //               "type": "text",
    //               "version": 1
    //             },
    //             {
    //               "detail": 0,
    //               "format": 2,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "interacts",
    //               "type": "text",
    //               "version": 1
    //             },
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": " with the editor itself – which can greatly hel",
    //               "type": "text",
    //               "version": 1
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [
    //             {
    //               "children": [
    //                 {
    //                   "detail": 0,
    //                   "format": 0,
    //                   "mode": "normal",
    //                   "style": "",
    //                   "text": "www.google.com",
    //                   "type": "text",
    //                   "version": 1
    //                 }
    //               ],
    //               "direction": "ltr",
    //               "format": "",
    //               "indent": 0,
    //               "type": "autolink",
    //               "version": 1,
    //               "rel": null,
    //               "target": null,
    //               "title": null,
    //               "url": "www.google.com"
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "greater diversity of solutions—if it’s appropriate, simply remind people that removing features is an option.",
    //               "type": "text",
    //               "version": 1
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "Nature, 2021. DOI: ",
    //               "type": "text",
    //               "version": 1
    //             },
    //             {
    //               "children": [
    //                 {
    //                   "detail": 0,
    //                   "format": 0,
    //                   "mode": "normal",
    //                   "style": "",
    //                   "text": "10.1038/s41586-021-03380-y",
    //                   "type": "text",
    //                   "version": 1
    //                 }
    //               ],
    //               "direction": "ltr",
    //               "format": "",
    //               "indent": 0,
    //               "type": "link",
    //               "version": 1,
    //               "rel": null,
    //               "target": null,
    //               "title": null,
    //               "url": "http://dx.doi.org/10.1038/s41586-021-03380-y"
    //             },
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "  (",
    //               "type": "text",
    //               "version": 1
    //             },
    //             {
    //               "children": [
    //                 {
    //                   "detail": 0,
    //                   "format": 0,
    //                   "mode": "normal",
    //                   "style": "",
    //                   "text": "About DOIs",
    //                   "type": "text",
    //                   "version": 1
    //                 }
    //               ],
    //               "direction": "ltr",
    //               "format": "",
    //               "indent": 0,
    //               "type": "link",
    //               "version": 1,
    //               "rel": null,
    //               "target": null,
    //               "title": null,
    //               "url": "http://arstechnica.com/science/news/2010/03/dois-and-their-discontents-1.ars"
    //             },
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": ").",
    //               "type": "text",
    //               "version": 1
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [
    //             {
    //               "detail": 0,
    //               "format": 2,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "John is Ars Technica’s science editor. He has a Bachelor of Arts in Biochemistry from Columbia University, and a Ph.D. in Molecular and Cell Biology from the University of California, Berkeley. When physically separated from his keyboard, he tends to",
    //               "type": "text",
    //               "version": 1
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "hello this is a ",
    //               "type": "text",
    //               "version": 1
    //             },
    //             {
    //               "children": [
    //                 {
    //                   "detail": 0,
    //                   "format": 0,
    //                   "mode": "normal",
    //                   "style": "",
    //                   "text": "link",
    //                   "type": "text",
    //                   "version": 1
    //                 }
    //               ],
    //               "direction": "ltr",
    //               "format": "",
    //               "indent": 0,
    //               "type": "link",
    //               "version": 1,
    //               "rel": "noreferrer",
    //               "target": null,
    //               "title": null,
    //               "url": "www.google.com"
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [
    //             {
    //               "detail": 0,
    //               "format": 0,
    //               "mode": "normal",
    //               "style": "",
    //               "text": "hello",
    //               "type": "text",
    //               "version": 1
    //             }
    //           ],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "type": "VideoInputNode",
    //           "value": [
    //             {
    //               "publicUrl": "https://www.youtube.com/watch?v=QrR_gm6RqCo"
    //             }
    //           ],
    //           "id": "qmJoI8"
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "type": "ImageInputNode",
    //           "value": [
    //             {
    //               "publicUrl": "https://pocket-image-cache.com/direct?resize=w2000&url=https%3A%2F%2Fcdn.arstechnica.net%2Fwp-content%2Fuploads%2F2021%2F04%2FJimPennucci_Flickr_SwissArmyKnifeVictorinox-800x532.jpeg"
    //             }
    //           ],
    //           "id": "EDRkrN"
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "type": "ImageInputNode",
    //           "value": [
    //             {
    //               "publicUrl": "https://pocket-image-cache.com/direct?resize=w2000&url=https%3A%2F%2Fcdn.arstechnica.net%2Fwp-content%2Fuploads%2F2021%2F04%2FJimPennucci_Flickr_SwissArmyKnifeVictorinox-800x532.jpeg"
    //             }
    //           ],
    //           "id": "Iv4ls6"
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": "ltr",
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": null,
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "type": "ImageInputNode",
    //           "value": [
    //             {
    //               "publicUrl": "data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7"
    //             }
    //           ],
    //           "id": "DcCQei"
    //         },
    //         {
    //           "children": [],
    //           "direction": null,
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         },
    //         {
    //           "children": [],
    //           "direction": null,
    //           "format": "",
    //           "indent": 0,
    //           "type": "paragraph",
    //           "version": 1
    //         }
    //       ],
    //       "direction": "ltr",
    //       "format": "",
    //       "indent": 0,
    //       "type": "root",
    //       "version": 1
    //     }
    //   }
    // }
  });
  const [consolidated, setConsolidated] = useState(undefined)
  const handleSetLex = ({ valuePath, value }) => {
    console.log({ valuePath, value });
    setLex((prev) => {
      let newValue = cloneDeep(prev);
      newValue = setVal(newValue, valuePath, value);
      return newValue;
    });

    setConsolidated(prev => {
      let newConsolidated = cloneDeep(prev) || {};
      const {annoData = {}} = value || {};

      //first remove all fragments from prev, that belong to this rte valuepath
      //cuz in the next step we will refill the data with the fragments in this 
      //latest updated value from this valuepath

      Object.keys(newConsolidated).map(tagType => {
        if(newConsolidated[tagType].data?.length > 0){
          newConsolidated[tagType].data.forEach((tag, tagIdx) => {
            if(tag.fragments?.length > 0){
              newConsolidated[tagType].data[tagIdx].fragments = newConsolidated[tagType].data[tagIdx].fragments.filter(frag => frag.extractedFromValuePath !== valuePath)
            }
          })
        }
      })

      

      //now inject this new data without a care into whatever is left of newConsolidated

      Object.keys(annoData).map(annoDataId => {
        const {tags, fragment = {}} = annoData[annoDataId]
        const tagTypes = Object.keys(tags || {});
        if(tagTypes.length > 0){
          tagTypes.forEach(tagType => {
            const value = tags[tagType];

            if(newConsolidated[tagType]?.data?.length > 0){
              value.data.forEach(d => {
                const foundTagIdx = newConsolidated[tagType].data.findIndex(dd => dd._id === d._id)
                if(foundTagIdx !== -1){
                  const fragsAry = newConsolidated[tagType].data[foundTagIdx].fragments || []
                  if(fragsAry.length === 0){
                    newConsolidated[tagType].data[foundTagIdx].fragments = [ {...fragment, annoDataId, extractedFromValuePath: valuePath} ]
                  }else{
                    newConsolidated[tagType].data[foundTagIdx].fragments.push({...fragment, annoDataId, extractedFromValuePath: valuePath})
                  }
                  
                }else{
                  newConsolidated[tagType].data.push({...d, fragments: [{...fragment, annoDataId, extractedFromValuePath: valuePath}]})
                }
              })
            }else{
              newConsolidated[tagType] = {
                collectionId: value.collectionId,
                data: value.data.map(d => ({...d, fragments: [{...fragment, annoDataId, extractedFromValuePath: valuePath}]}))
              }
            }

            //remove the tags that have no fragments against them anymore
            newConsolidated[tagType].data.forEach((d,i) => {
              if(!d.fragments?.length){
                newConsolidated[tagType].data.splice(i,1)
              }
            })
          })
        }else{ //reset newConsolidated to empty object if not tagTypes are only left in annoData
          newConsolidated = {}
        }
      })
      return newConsolidated
    })
  };

  console.log({
    consolidated,
    LEX_EDITOR: lex,
    RICH_TEXT_EDITOR: rte,
    OLD_EDITOR: rteOld,

    // SIMPLE_TEXT_EDITOR: srte
  });

  const [readOnly, setReadOnly] = useState(false);

  return (
    <Container>
      <PaddingTop20 />
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <CheckboxButton
          value={readOnly}
          saveValueAsString
          onChange={(k, v) => setReadOnly(v)}
        />
        <SANS_2>Read Only</SANS_2>
      </div>

      {/* <PaddingTopBottom40>
        <DEPRECATED_SimpleTextEditor 
          label={'Simple Text Editor'}
          value={srte} 
          onChange={(k, v) => setSrte(v)} 
          charLimit={10}
          readOnly={readOnly}
          />
      </PaddingTopBottom40> */}
      <PaddingTopBottom40>
        <Wrapper700 left>
        <LexicalTextEditor
          label={"Simple Lex Editor"}
          value={rteSimp}
          onChange={(k, v) => setRteSimp(v)}
          variant={"simple"}
        />
          <PaddingBottom40/>
          {
            consolidated?.cars24?.data.map(d => 
              {
                console.log({lexvalue: d.fragments[0]})
                return(
                  <div
                  // onMouseOut={e => console.log('mouse out ', e.target)}
                  onMouseLeave={e => {
                    const markHTMLNodes = e.currentTarget.getElementsByTagName('mark')
                    Array.from(markHTMLNodes).map(node => node.classList.remove('-hovered-'))
                  }}
                  onMouseOver={e => {
                    // console.log(e.target)
                    const markHTMLNodes = e.currentTarget.getElementsByTagName('mark')
                    if(Array.from(markHTMLNodes).some(node => node.contains(e.target))){
                      Array.from(markHTMLNodes).map(node => node.classList.add('-hovered-'))
                    }else{
                      Array.from(markHTMLNodes).map(node => node.classList.remove('-hovered-'))
                    }
                  }}
                  >
              <LexicalTextEditor
              key={JSON.stringify(d)} //makes the comp reinitiate and therefore except the new value. this is a new hack i have found to get uncontrolled comps to rerender when their value changes from outside
              id={'fragment'}
                value={d.fragments[0]}
                readOnly={true}
                />
                </div>
                
            )})
          }
          <LexicalTextEditor
          id={'someunique1'}
            label={"Lexical Editor"}
            onChange={(k, v) => handleSetLex({ valuePath: "path1", value: v })}
            value={lex?.path1}
            annotation={{
              enable: true,
              tagTypesConfig: [
                {
                  tagType: "cars24",
                  allowCreateTag: false,
                },
                {
                  tagType: "stories",
                  allowCreateTag: false,
                },
              ],
            }}
          />
          <LexicalTextEditor
          id={'someunique2'}
            label={"Lexical Editor"}
            onChange={(k, v) => handleSetLex({ valuePath: "path2", value: v })}
            value={lex  .path2}
            // readOnly={true}
            enableAIAnnotation
            annotation={{
              enable: true,
              tagTypesConfig: [
                {
                  tagType: "cars24",
                  allowCreateTag: false,
                },
                {
                  tagType: "stories",
                  allowCreateTag: false,
                },
              ],
            }}
          />
          <PaddingTop20 />
          <LexicalTextEditor
            variant="simple" 
            label={"Text Limit"}
            charLimit={15}
            readOnly={readOnly}
          />
        </Wrapper700>
      </PaddingTopBottom40>
      <Wrapper700 left>
        <PaddingTopBottom40>
          <RichTextEditor
            label={"Rich Text Editor"}
            value={rte}
            onChange={(k, v) => setRte(v)}
            readOnly={readOnly}
            annotation={{
              enable: true,
              tagTypesConfig: [{ tagType: "cars24" }],
            }}
            // charLimit={10}
          />
        </PaddingTopBottom40>
      </Wrapper700>
      <PaddingTopBottom40>
        {/* <RichTextEditor
          label={"New Simple Editor"}
          value={rteSimp}
          onChange={(k, v) => setRteSimp(v)}
          variant={"simple"}
          charLimit={125}
          readOnly={readOnly}
        /> */}
      </PaddingTopBottom40>
      <PaddingTopBottom40>
        <KPRichInput
          label={"Old Rich Text Editor"}
          value={rteOld}
          onChange={(k, v) => setRteOld(v)}
          superRichFormattable
          readOnly={readOnly}
          annotation={{
            enable: true,
            tagTypesConfig: [{ tagType: "milestones" }],
          }}
        />
      </PaddingTopBottom40>
      <StaticRichText label="Static Rich Text" sublabel="Sublabel" richContent={rte} />
    </Container>
  );
};
