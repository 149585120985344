import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { getTenant } from "../../TENANT_CONFIGS";

import Components from "./comps";

const tenantPluginRoutes = {
  wqn: [
    {
      path: "/WQMCourse",
      comp: "WQMCourse",
    },
  ],
  nkms: [
    {
      path: "/mediaLibrary",
      comp: "MediaLibrary",
    },
  ],
};

export default function PluginRoutes() {
  const {path} = useRouteMatch()  
  const thisTenantPluginRoutes = tenantPluginRoutes[getTenant()];
  if (!thisTenantPluginRoutes) return null;

  return (
    <>
      {thisTenantPluginRoutes.map((d) => (
        <Route
          exact
          key={d.path}
          path={path+d.path}
          component={Components[d.comp]}
        />
      ))}
    </>
  );
}
