

export const defaultListingStyle = 'CardsStyle'

//this is a very quick shabby thing that i have done.
//but my sense is, we should fallback to a default sort
//irrespective of configs being there in the database
export const defaultSortConfig = [
    {
      display: "Latest",
      sort: {"kp_date_published" : -1},
      value: 'newest'
    },
    {
      display: "Oldest",
      sort: {"kp_date_published" : 1},
      value: 'oldest'
    },
  ];
