import { toArray } from 'oolib'
import { queryClient } from '../../index'
import { __GetAllContentTypeConfig, __GetProfileTypeConfig } from '../getters/gettersV2'

export const __IsAuthor = (contributedById, userId) => {
	return (
		//basically if both contributedById & usedId are falsy, we dont want this to return true
		contributedById && 
		userId && 
		contributedById === userId

	)
}

export const __IsSuperAdmin = (user) => ['superAdmin', 'god'].indexOf(user?.role) !== -1
export const __IsAdmin = (user) => user?.role === 'admin'

/**
 * this is temp and not robust enough. it blocks out ui elements on frontend, 
 * but ideally, we need a more complex configuration on the backend, that ensures that,
 * some things just cannot be accessed by non super admins on the frontend as well as at 
 * the api level.
 */
export const isBlacklistedForAllButSuperAdmins = ({type, payload, currentUser}) => {
	
	if(currentUser?.role === 'superAdmin') return false;

	switch(type){
		case 'roleOptions':
			return payload.value === 'superAdmin' ? true : false;
	}
	
}

const extendedReadIfMemberCheck = ({accessConfig, hasAccess, user, contentType, contentId}) => {
	//extra check if the ACTION in question is 'READ_IF_MEMBER'
	//1/ if user has read access, then that overrules READ_IF_MEMBER
	if(accessConfig.includes('READ')) return true;
	//2/ so now we know user doesnt have READ access, so a !hasAccess means return false
	
	if(!hasAccess) return false;
	//3/ else, user has READ_IF_MEMBER access 
	// so now check if the contentId passed through exists in user's taggedResources
	//that is our proxy (for now) to check if user is member 
	let userIsTaggedOnThisContent = user.taggedResources[contentType]?.data.some(
		d => d._id === contentId
	)

	return userIsTaggedOnThisContent ? true : false
}

// default to 'no' access unless defined otherwise
export const __CheckAccessToContentType = (user, ACTION, contentType, options={}) => {
	const { _Roles } = queryClient.getQueryData('platformConfigs')
	let role = !user ? '0' : user.role
	let accessConfig = _Roles[role] && _Roles[role].contentTypes[contentType]
	let hasAccess = accessConfig ? accessConfig.includes(ACTION) : false
	
	if(ACTION !== 'READ_IF_MEMBER') return hasAccess
	//else action is READ_IF_MEMBER
	if(options.ignoreExtendedIsMemberCheck){
		return (
		accessConfig.includes('READ') || 
	    accessConfig.includes('READ_IF_MEMBER')
		) 
	  }
	return extendedReadIfMemberCheck({
		accessConfig, 
		hasAccess, 
		user, 
		contentType, 
		contentId: options.contentId
	})

	
}

export const __CheckSomeAccessToSomeContentType = ({user, actions, contentTypes}) => {
	const { _Roles } = queryClient.getQueryData('platformConfigs')
	let role = !user ? '0' : user.role
	return toArray(contentTypes).some(con => {
		let accessConfig = _Roles[role].contentTypes && _Roles[role].contentTypes[con] 
		return !accessConfig 
			? false
			: toArray(actions).some(ac => accessConfig.includes(ac))
	})
}

export const __GetAccessibleContentTypes = ({user, ACTION, contentTypes}) => {
	const allContentTypes = __GetAllContentTypeConfig()
	const configContentTypes = allContentTypes.map(contentObj=> contentObj.kp_content_type);

	return (contentTypes || configContentTypes).filter(contentType=> __CheckAccessToContentType(user, ACTION, contentType))
	// returns something like [ 'stories', 'tools', ... ]
}


export const __GetAccessibleContentTypesForTheUser = ({ user, ACTION }) => {
  const allContentTypesConfig = __GetAllContentTypeConfig();

  return allContentTypesConfig.filter((config) =>
    __CheckAccessToContentType(user, ACTION, config.kp_content_type)
  );
};


// default to 'no' access unless defined otherwise
export const __CheckAccessToModule = (user, ACTION, module) => {
	const { _Roles } = queryClient.getQueryData('platformConfigs')
	let role = !user ? '0' : user.role
	let accessConfig = _Roles[role].modules && _Roles[role].modules[module] // --> is a boolean flag. if undefined, this will return false, which is what we want.
	return accessConfig ? accessConfig.includes(ACTION) : false
}

// is true if even one action is allowed against even just one module.
export const __CheckSomeAccessToSomePlatformBuilderModule = ({user, actions, modules}) => {
	
	const { _Roles } = queryClient.getQueryData('platformConfigs')
	let role = !user ? '0' : user.role
	return toArray(modules).some(mod => {
		let accessConfig = _Roles[role].platformBuilder && _Roles[role].platformBuilder[mod] 
		return !accessConfig 
			? false
			: toArray(actions).some(ac => accessConfig.includes(ac))
	})
}

export const __CheckAccessToProfileType = (user, ACTION, profileType) => {
	const { _Roles } = queryClient.getQueryData('platformConfigs')
	let role = !user ? '0' : user.role
	let accessConfig = _Roles[role].community[profileType]

	return accessConfig ? accessConfig.includes(ACTION) : false
}

export const __IsProfileOwner = (createdById, userId) => {
	return createdById === userId
}


/**
 * if _PrivatePlatform, then
 * if user logged in, allow access
 * if user not logged in DONT allow access
 */
export const __CheckAccessToPrivatePlatform = (user) => {
	const { _Roles, deployment : {_PrivatePlatform } } = queryClient.getQueryData('platformConfigs')
	if(!_PrivatePlatform) return true;
	let role = !user ? '0' : user.role
	return role === '0' ? false : true

}

export const __CheckAccessViaConfig = ({
	user,
	checkAccessTo,
	action,
	resourceType, //should have called it resourceType...
	resourceId
}) => {
	let fns = {
		profileType: __CheckAccessToProfileType,
		contentType: __CheckAccessToContentType,
		contentTypeTemp:__CheckSomeAccessToSomeContentType,
		module: __CheckAccessToModule
	}

	if(checkAccessTo==="contentTypeTemp"){
		return fns[checkAccessTo]({user, actions:action, contentTypes:resourceType})
	}

	return fns[checkAccessTo](
		user,
		action,
		resourceType,
		{ contentId: resourceId }
	)


}


// ----- VERY USE CASE SPECIFIC ACCESS CONTROL 'COMBOS' ------ //

/**
 * time and again we have a usecase where we need to check if 
 * current user can access THIS resource / feature / module if
 * they are either the owner / author of the resource OR
 * if they have 'MODERATE' access to that resource.
 * 
 * This function does that check for you and returns 
 * hasAccess : Boolean
 * hasAccessAs : 'owner' / 'moderator' // cant think of an immediate usecase for this datapoint, but could be handy in the future
 */

export const __CheckAccessToActionAsOwnerOrModerator = (
	user,
	authorId,
	ACTION,
	accessControlFn,
	resource_feature_moduleId 
) => {

	const accessControlFns = {
		__CheckAccessToContentType,
		__CheckAccessToModule,
		__CheckAccessToProfileType
	}

	let isOwner = user?._id === authorId;
	let canDoAction = accessControlFns[accessControlFn](user, ACTION, resource_feature_moduleId);
	let canModerate = accessControlFns[accessControlFn](user, 'MODERATE', resource_feature_moduleId);
	
	

	let hasAccess = canModerate ? true : (canDoAction && isOwner) ? true : false
	// if doesnt have access only then leave as undefined else, prioritize owner access for 'as'
	let hasAccessAs = !hasAccess ? undefined : canDoAction ? 'owner' : 'moderator'

	return ({hasAccess, hasAccessAs})
}


export const __CheckAccessToPublishCTA = (user) => {
	const {
		deployment: { _ShowPublishEntryPointsToUnAuth },
	} = queryClient.getQueryData('platformConfigs')
	/**
	 * if unauth user and platform allows showing
	 * publish entry points ( so that at least they can be redirected to
	 * a login screen after which a final access check can be made )
	 * ... then, __CheckAccessToFeature & if true, then show.
	 */
	if (!user && _ShowPublishEntryPointsToUnAuth) {
		return true
	}
	//else
	/**
	 * do our standard content access control check + feature access check.
	 * and return whatever it says
	 */
	return (
		__CheckSomeAccessToSomeContentType({
			user,
			actions: ['SUBMIT', 'PUBLISH'],
			contentTypes: 
			   __GetAllContentTypeConfig()
					.map(c => c.kp_content_type)
					.filter(id => id !== 'staticPages')
		})
	)
}

// login pretty much the same as above
export const __CheckAccessToListingPageCTA = (user, contentType) => {
	const {
		deployment: { _ShowPublishEntryPointsToUnAuth },
	} = queryClient.getQueryData('platformConfigs')

	if (!user && _ShowPublishEntryPointsToUnAuth) return true
	//else
	return __CheckSomeAccessToSomeContentType({
		user, 
		actions: ['PUBLISH', 'SUBMIT'], 
		contentTypes: contentType
	})
}

// ----- END VERY USE CASE SPECIFIC ACCESS CONTROL 'COMBOS' ------ //

//phase out eventually.
// export const checkAccessToContentType = (user, contentType, roles, userAction) => {
//   return roles[user.role].knowledge.contentTypes[contentType].includes(userAction)
// }

export const profileTypeBased_checkAccessToFeature = (user, feature) => {
	let profileTypeConfig = __GetProfileTypeConfig(user?.profileType)
	return profileTypeConfig.access[feature]
}

export const _GetPublishOrSubmitAction = (user, contentType) => {
	switch (true) {
		case __CheckAccessToContentType(user, 'SUBMIT', contentType) === true:
			return {
				action: 'SUBMIT',
				display: { onDraft: 'Submit', onEditPublished: 'Re-Submit' },
			}
		case __CheckAccessToContentType(user, 'PUBLISH', contentType) === true:
			return {
				action: 'PUBLISH',
				display: { onDraft: 'Publish', onEditPublished: 'Publish Changes' },
			}

		default:
			//means no PUBLISH, no SUBMIT
			return undefined
	}
}

