export const stockResearchNotesCardConfig = {
  cardHeader: [
    {
      comp: "Title",
      valuePath: "main.title",
    },
  ],

  cardBody: [
    {
      comp: "ClampedRichInput",
      valuePath: "annotations.tags.[annoTagType].data",
    },
  ],
  metaBlocks: [
 
    {
      comp: "Meta",
      props: {},
      useModifier: "date",
      valuePath: "kp_date_published",
    },
    {
      comp: "Meta",
      props: {},
      useModifier: "tplTitle",
      valuePath: "meta.kp_content_type",
    },
  ]
};
