export const notifLinkGenerator = ({ activityType, dateCreated, content }) => {

  switch (activityType) {
    case "ADD_COMMENT":
      return `/published-page/${content.contentType}?id=${content.id}#Comment_${content.commentId}`;
    case "ADD_REACTION":
    case "ADD_REACTION_LIKE": //purely for backwards compatibility
    case "MODERATE_APPROVE":
      return `/published-page/${content.contentType}?id=${content.id}`;
    default:
      return window.location;
  }
};
