export const highlightPlugin = ({ cardData, cardConfig }) => {
    
    const campaigns = cardData?.tags?.campaigns?.data

    const highlight = (Array.isArray(campaigns) && campaigns[0]?.display) ? true : false 
    
    const tagDisplayPath = (highlight && campaigns[0]?.display) ? "tags.campaigns.data.0.display" : null

    return {
        cardData: {
            ...cardData,
            highlight
        },
        cardConfig: {
            ...cardConfig,
            tagDisplay: tagDisplayPath
        }
    }
}