import React from 'react';
import { useState } from "react";
import { Shell } from "./comps/Shell";
import { colors, WrapperCardGrid } from 'oolib';
import styled from 'styled-components';

export const StyledContentBlock = styled.div`
  height: 220px;
  border-radius: 10px;
  border: 2px solid #E9E9E9;
`;


export const ListingShell = () => {

    const options = [ 
      { display: "Cards", value: "value1" },
      { display: "Table", value: "value2" },
      { display: "display 3", value: "value3" },
      { display: "display 4", value: "value4" },
      { display: "display 5", value: "value5" },
      { display: "display 6", value: "value6" },
    ]
    const [ active, setActive ] = useState(options[0]);
  
    return (
      <Shell 
          title={"Case Reports"}
          desc={"Spending time in nature has been shown to reduce stress, anxiety, and depression. The change of scenery and the presence of greenery can have a calming effect, which is especially beneficial for gamers who might experience screen fatigue or burnout."}
          CTAtext={"Add Case Report"}
          filter={true}
          tabBarConfig={{
            tabBarOptions: options,
            tabBarOnChange: (value) => setActive(value),
            tabBarSelected: active
          }}
          search={true}
          sort={true}
          table={active.value === "value2"}
          contentConfig={ // Object or a HTML/jsx element
            active.value === "value1" ? 
            {
              layout : 'center',
              content : <WrapperCardGrid>
                            {[...Array(100)].map((_, index) => (
                              <StyledContentBlock key={index} />
                            ))}
                        </WrapperCardGrid>
            }
            :
            {
              layout : 'left',
              content : <div style={{ backgroundColor: colors.black_opacity30, width: '100%', height: '100svh', borderRadius: '20px', padding: '40px' }}>
                          Table
                        </div>
            } 
        } 
      />  
    )
  }