import axios from "axios";
import { getTenant } from "./TENANT_CONFIGS";

export const setTenantRequestHeader = () => {
  let tenantId = getTenant();
  axios.defaults.headers.common["tenant"] = tenantId;
};

export const setBackendUrlInProduction = () => {
if(process.env.NODE_ENV === 'production'){
   axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
   axios.defaults.withCredentials = true;
}
 
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["x-auth-token"] = token;
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
  }
};
