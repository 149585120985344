import axios from "axios";
import { useInfiniteQuery } from "react-query";
import { useAppSettingsContext } from "../../contexts/appSettingsContext";
import { useGenErrorMsgBanners } from "../useGenErrorMsgBanners";
import { defaultSortConfig } from "../../pageTpls/PublishedListing/config/defaults";
import { buildListingPageQueryForKS } from "../okf-ks-utils/buildListingPageQueryForKS";
import { defaultUserProfilesSortConfig } from "../../pageTpls/ProfilesListing/configs/defaultUserProfilesSortConfig";

const getTagsV4 = (type, options = {}) => {
  let apiString, queryParams;

  if (options.tagCategory === "userTags") {
    apiString = "/api/users/getAllUsers";
    queryParams = {
      limit: 20,
        skip: options.skip,
      findQuery: JSON.stringify({
        profileType: type,
        ...(options.filters || {}),
      }),
      // activeSort: defaultUserProfilesSortConfig,
      searchTerm: options.searchTerm,
      ksConfig: options.searchTerm &&
        buildListingPageQueryForKS({
          keyword: options.searchTerm,
          config: { fields: ["main.name.autoComplete"] },
          activeSort: defaultUserProfilesSortConfig,
        }),
      postDALFilter: JSON.stringify(options.postDALFilter || {}),
    };
  } else if (options.tagCategory === "contentTags") {
    apiString = "/api/discovery/getData";
    queryParams = {
      configs: JSON.stringify({
        contentTypes: [type],
        findQuery: {
          ...(options.filters || {}),
          kp_published_status: 'published'
        },
        limit: 20,
        skip: options.skip,
        activeLang: options.activeLang,
        searchTerm: options.searchTerm,
        ksConfig: options.searchTerm &&
          buildListingPageQueryForKS({
            keyword: options.searchTerm,
            config: { fields: ["main.title.autoComplete"] },
            activeSort: defaultSortConfig[0].sort,
          }),
        // activeSort: defaultSortConfig[0].sort
      }),
    };
  } else {
    apiString = `/api/v3/tags/${type}`;
    queryParams = {
      findQuery: JSON.stringify({
        ...(options.filters || {})
      })
    }
    
  }

  return axios.get(apiString, { params: queryParams }).then((res) => {
    return res.data;
  });
};

export const useGetTagsV4 = (type, options = {}) => {
  const { APP_SETTINGS } = useAppSettingsContext();
  const activeLang = APP_SETTINGS.lang.value;
  const {genErrorMsgBanners} = useGenErrorMsgBanners()
  //multiple
  const query = useInfiniteQuery({
    queryKey: ["tagsV4", type, options, activeLang],
    queryFn: ({ pageParam: skip = 0 }) => {
      return getTagsV4(type, { ...options, activeLang, skip })
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.skip;
    },
    enabled: options.enabled,
    onError: (err) => {
      genErrorMsgBanners({err})			
    },
  });
  return query;
};

