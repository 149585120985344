import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'


export const useUpdateNotifIsRead = () => {
	const queryClient = useQueryClient();


	return useMutation(
		({userId}) => {
			// const config = { headers: { 'Content-Type': 'application/json' } }
			return axios.patch(`/api/notifications/set_is_read/${userId}`)
			
		},
		{
			onError: (error) => {
				console.log('error updating notif is read', error)
			},
			onSuccess: () => {
				queryClient.invalidateQueries(["userData"])
				console.log('Notif is read updated successfully!')
			},
		},
	)
}
