import styled, { css } from "styled-components";
import { colors } from "oolib";

const { black_opacity80, greyColor90, greyColor100 } = colors;

const caretCommonCss = css`
  position: absolute;
  cursor: pointer;
  z-index: 99999;
  top: 50%;
`


export const StyledCaretLeft = styled.div`
  ${caretCommonCss};
  transform: translateY(-50%);
  left: 1rem;
  
`;

export const StyledCaretRight = styled.div`
  ${caretCommonCss};
  transform: translateY(-50%);
  right: 2rem;
`;


export const StyledModalWrapper = styled.div`
    z-index: 99998;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${black_opacity80} 
`;

export const StyledModal = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

export const StyledActionWrapper = styled.div`
    z-index: 10;
    display: flex;
    gap: 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
`

export const StyledTagsWrapper = styled.div`
    height: 100%;
    background: ${greyColor90};
    border-right: 1px solid ${greyColor100};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`