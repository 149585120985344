import React, { Fragment, useEffect, useState } from "react";

import {
  CompositeDecorator, convertFromRaw, convertToRaw, Editor,
  EditorState
} from "draft-js";

import "draft-js/dist/Draft.css";
import { onDraftEditorCopy, onDraftEditorCut } from "draftjs-conductor";

import {
  StyledEditor, StyledEditorAdjunct
} from "../styled";

import Toolbar from "./comps/Toolbar";
import { inlineStyleMap } from "./config";

//import { BlockLabel } from '../../BlockLabel';
//import { getBlockLabelProps } from '../../uitls';
import { BlockLabel, getBlockLabelProps } from "oolib";

import { useRef } from "react";
import { CharLimitBanner } from "./comps/CharLimitBanner";
import { LinkOrEmbedModal } from "./comps/LinkOrEmbedModal";
import { genLinkDecorator } from "./comps/LinkOrEmbedModal/utils/genLinkDecorator";
import { PastedLinkToEmbedLightbox } from "./comps/PastedLinkToEmbedLightbox";
import {
  advancedHandlePastedText, handleBeforeInput, handleKeyCommand, handlePastedorDroppedFiles, handleReturn
} from "./handlers";
import { useRTEContext } from "./RTEContext";
import {
  renderAtomicBlock, scrollDownIfNecessary, setClassNamesToBlockTypes
} from "./utils";
import { genAnnoDecorator, editAnnotation, insertAnno, removeAnnotation } from "../../KPRichInput/annotation";
import AnnoLightbox from "../../KPRichInput/annotation/comps/NEW/AnnoLightbox";
import { handleCutText } from "./handlers/handleCutText";
import { _Locale } from "../../../locale/Locale";

export const Main = (props) => {
  const {
    enableConductor,
    id,
    invert,
    // isRequired,
    onChange,
    placeholder,
    readOnlyProp,
    typo,
    value,
    charLimit,
    placeholderColor,
    disableNewline,
    variant,
    showAnnoLightbox,
    setShowAnnoLightbox,
    onAnnoClick,

    showLinkOrEmbedModal,
    setSomeTextIsSelected,
    setShowConvertToEmbedLightbox,
    showConvertToEmbedLightbox,
    setRetainAnnoOnPaste,
    retainAnnoOnPaste,
    textAlignment,
    forceValue,
    style
  } = useRTEContext();

  const [showToolbar, setShowToolbar] = useState(false);
  const handleShowToolbar = (bool) => {
    if (variant === "simple") return;
    setShowToolbar(bool);
  };

  const [readOnly, setReadOnly] = useState(readOnlyProp);

  useEffect(() => {
    setReadOnly(readOnlyProp); //only used in playground for now where we toggle readOnly
    // note that the above state change doesnt trigger an atomic block rerender.
    // to do that we need to EditorState.forceSelect (line 204)
    // but for some strange reason doing that here is causing wierd bugs in an embedded image caption
  }, [readOnlyProp]);

  const EditorRef = useRef(null);

  const genDecorator = () =>
    new CompositeDecorator([
      genLinkDecorator({
        invert,
      }),
      genAnnoDecorator({
        EditorRef,
        onAnnoClick,
        readOnly: readOnlyProp,
      }),
    ]);

  const [editorState, setEditorState] = useState(
    !value
      ? EditorState.createEmpty(genDecorator())
      : EditorState.createWithContent(
          convertFromRaw(
            { entityMap: {}, ...value } //inject empty entity map, if it doesnt exist in value. else error will hapn
          ),
          genDecorator()
        )
  );

  //in some cases we wanna push a value into the RTE from outside this comp.
  //we cant simply put a use effect on props.value to do this, because it causes strange
  //bugs with selection state.
  useEffect(() => {
    if (forceValue) {
      const compatibleValue = forceValue.blocks
        ? forceValue
        : { blocks: [{ text: forceValue.toString() }], entityMap: {} };

      let newEditorState = EditorState.push(
        editorState,
        convertFromRaw(compatibleValue),
        "change-block-data"
      );

      handleChange(newEditorState);
    }
  }, [forceValue]);

  // if (!value && readOnlyProp) return null;

  const handleChange = (newState, ops = {}) => {
    // console.log({
    //   ED_SEL: newState.getSelection(),
    //   SEL: window.getSelection(),
    //   anchorNode: window.getSelection()?.anchorNode,
    //   parentOfAnchor: window.getSelection().anchorNode?.parentNode,
    //   contentState: convertToRaw(newState.getCurrentContent()),
    //   start: newState.getSelection().getStartOffset(),
    //   end: newState.getSelection().getEndOffset(),
    //   isCollapsed: newState.getSelection().isCollapsed()
    // })

    const someTextIsSelected = !newState.getSelection().isCollapsed();

    setSomeTextIsSelected(someTextIsSelected);

    /**
     * this isFocusCall is a tricky one.
     * Whats its purpose: to ensure that we dont fire an unnecessary 'parent onChange' if its simply a focus call, and not really a 'content' update
     */
    const isFocusCall =
      !editorState.getSelection().hasFocus &&
      ops?.firedFrom === "EditorComponent"; //not true unless old state hadn't focus
    const isBlurCall =
      !newState.getSelection().hasFocus && ops?.firedFrom === "EditorComponent";

    //handle focus call
    if (isFocusCall) {
      setEditorState(newState);
      handleShowToolbar(true);
      return;
    }
    //else

    const newContent = newState.getCurrentContent();
    const newContentRaw = convertToRaw(newContent);

    //handle blur call
    if (
      isBlurCall &&
      editorState.getCurrentContent().getPlainText().trim() === "" && //no text
      newContentRaw.entityMap[0] === undefined //no atomic blocks
    ) {
      //PENDING: if there are empty lines at the start or end of the content, then
      //remove them. (verify with shen)

      const resetEmptyState = EditorState.createEmpty();
      const resetEmptyContentRaw = convertToRaw(
        resetEmptyState.getCurrentContent()
      );

      setEditorState(resetEmptyState);
      onChange && setTimeout(() => onChange(id, resetEmptyContentRaw), 0);
      return;
    }
    // else if it is a blur call, but the editor DOES have content. then dont fire on change
    if (isBlurCall) {
      setEditorState(newState);
      return;
    }

    //else
    scrollDownIfNecessary(newState.getSelection());
    /**
     * 'EditorState.forceSelection(newState, newState.getSelection())'
     * recommended hack on draftjs github to force editor to re-render with changes.
     * especially in the case of Atomic Block updates. might also be relevant for
     * inline entity comp updates.
     *
     * Read full issue thread here: https://github.com/facebookarchive/draft-js/issues/1702
     */
    switch (ops?.firedFrom) {
      case "replaceEntityData":
        setEditorState(
          EditorState.forceSelection(newState, newState.getSelection())
        );
        break;
      case "mergeEntityData":
        setEditorState(
          EditorState.forceSelection(newState, newState.getSelection())
        );
        break;
      case "RTEInsertLink":
        console.log("reset decorator");
        setEditorState(
          EditorState.set(newState, { decorator: genDecorator() })
        );
        break;
      default:
        setEditorState(newState);
        break;
    }
    onChange && setTimeout(() => onChange(id, newContentRaw, newState), 0);
  };

  const genPastedLinkToEmbedLightbox = () => {
    return (
      <PastedLinkToEmbedLightbox
        EditorRef={EditorRef}
        editorState={editorState}
        handleChange={handleChange}
      />
    );
  };

  return (
    <Fragment>
      {showLinkOrEmbedModal && (
        <LinkOrEmbedModal
          mode={showLinkOrEmbedModal.mode}
          editorState={editorState}
          handleChange={handleChange}
        />
      )}
      <StyledEditor
        ref={EditorRef}
        invert={invert}
        style={style}
        className={`StyledEditor ${typo || ""}`}
        bottomMargin={showToolbar}
        placeholderColor={placeholderColor}
      >
        {/*parent will have typo class provider*/}
        {showConvertToEmbedLightbox && genPastedLinkToEmbedLightbox()}
        <BlockLabel {...getBlockLabelProps(props)} />
        {showAnnoLightbox && (
          <AnnoLightbox
            data={showAnnoLightbox.data}
            position={showAnnoLightbox.position}
            editorState={editorState}
            onCancel={() => setShowAnnoLightbox(undefined)}
            onEdit={(data) =>
              editAnnotation(
                {
                  editorState,
                  onEditorChange: (k, v) => handleChange(v),
                  ...data,
                },
                () => setShowAnnoLightbox(undefined)
              )
            }
            onRemove={(data) =>
              removeAnnotation(
                {
                  editorState,
                  onEditorChange: (k, v) => handleChange(v),
                  ...data,
                },
                () => setShowAnnoLightbox(undefined)
              )
            }
            onCreate={(data) =>
              insertAnno(
                {
                  value: { tags: data.data },
                  editorState,
                  onEditorChange: (k, v) => handleChange(v),
                },
                () => setShowAnnoLightbox(undefined)
              )
            }
          />
        )}
        <Editor
          editorState={editorState}
          onChange={(newEditorState) =>
            handleChange(newEditorState, { firedFrom: "EditorComponent" })
          }
          placeholder={_Locale(placeholder)}
          readOnly={readOnly}
          onBlur={(ev) => handleShowToolbar(false)}
          onFocus={(ev) => handleShowToolbar(true)}
          handleKeyCommand={(command) =>
            variant === "rich" &&
            handleKeyCommand({ command, editorState, handleChange })
          }
          textAlignment={textAlignment}
          handleBeforeInput={(char, editorState) =>
            handleBeforeInput({
              char,
              editorState,
              handleChange,
              charLimit,
              editorVariant: variant,
            })
          }
          handleReturn={() =>
            handleReturn({
              charLimit,
              disableNewline,
              plainTextInEditor: editorState.getCurrentContent().getPlainText(),
            })
          }
          handlePastedText={(text, html, editorState) => {
            //very important that this function is returned.
            //because returning 'handled' within it, can prevent the default
            //onChange from firing which is what we want sometimes
            // ---- EXTREMELY BUGGY FUNCTION ----- //
            /**
             * by default draft js handles pasted text decently, but because we
             * have interfered we have ruined that also
             */
            return advancedHandlePastedText({
              text,
              html,
              editorState,
              handleChange,
              charLimit,
              enableConductor,
              editorVariant: variant,
              setShowConvertToEmbedLightbox,
              setRetainAnnoOnPaste,
              retainAnnoOnPaste,
            });
          }}
          handlePastedFiles={(files) =>
            variant === "rich" &&
            handlePastedorDroppedFiles({ files, editorState, handleChange })
          }
          handleDroppedFiles={(selection, files) =>
            variant === "rich" &&
            handlePastedorDroppedFiles({ files, editorState, handleChange })
          }
          blockStyleFn={variant === "rich" && setClassNamesToBlockTypes}
          blockRendererFn={(
            contentBlock // retrun the object with data used // undefined for default
          ) =>
            renderAtomicBlock({
              contentBlock,
              editorState,
              handleChange,
              setReadOnly,
              readOnlyProp,
            })
          }
          customStyleMap={inlineStyleMap}
          {...(!enableConductor
            ? {}
            : {
                onCopy: onDraftEditorCopy, //overRiding draftjs
                onCut: onDraftEditorCut, // onCopy and onCut
              })}
          //related to retaining annotation of cut-paste
          onCut={(editor, e) => {
            handleCutText({ e, editor, setRetainAnnoOnPaste });
          }}
        />
      </StyledEditor>

      <StyledEditorAdjunct noToolbar={!showToolbar}>
        {charLimit && !readOnly && (
          <CharLimitBanner
            invert={invert}
            plainTextInEditor={editorState.getCurrentContent().getPlainText()}
            charLimit={charLimit}
          />
        )}
        {!readOnly && variant !== "simple" && (
          <Toolbar
            id={id}
            editorState={editorState}
            handleChange={handleChange}
            showToolbar={showToolbar}
            EditorRef={EditorRef}
          />
        )}
      </StyledEditorAdjunct>
    </Fragment>
  );
};
