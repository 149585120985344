import React, { Fragment, Suspense, useEffect, useState } from 'react'
//react query
import { ReactQueryDevtools } from 'react-query/devtools'
import { Route, Router, Switch } from 'react-router-dom'

import * as Sentry from "@sentry/react";
import './App.scss'
import MaintenanceScreen from './components/generalUI/MaintenanceScreen'

import { DataPivot } from './components/analysisModules/DataPivot';
import WarningScreen from './components/generalUI/WarningScreen'
import { Footer } from './components/nav/Footer'
import PrivateRoute from './components/routing/PrivateRoute'
import RouteAccessModule from './components/routing/RouteAccessModule'
import RouteAccessOneContent from './components/routing/RouteAccessOneContent'
import RouteAccessOneProfile from './components/routing/RouteAccessOneProfile'
import RouteAccessSomeContent from './components/routing/RouteAccessSomeContent'
import RouteSuperAdmin from './components/routing/RouteSuperAdmin'
import { AppSettingsProvider } from './contexts/appSettingsContext'
import history from './history'
import ChooseTemplate from './pageTpls/ChooseTemplate'


import { BannerAlert, BannerInfo, GlobalStyles, Loader, useBannerContext } from 'oolib'
import OolibThemeProvider from "./OolibThemeProvider"
import { useOnlineStatusChange } from './utils/environmentConfig'
// import { __GetProfileTypeConfig } from './utils/getters/gettersV2'
import { useGetPlatformConfigs } from './utils/react-query-hooks/useGetPlatformConfigs'
import { useUserLoad } from './utils/react-query-hooks/auth'
import { useRegisterSw } from './utils/swUtils'
import { Coachmark } from "./components/CoachmarkV2";




import { hotjar } from 'react-hotjar'



import { Header } from './components/nav/Header'
import CacheStatus from './components/PWA/CacheStatus'


import {  useGetTplsAndCacheSeparately } from './utils/react-query-hooks/tpls'
import { LocalhostTenantSwitcher } from './components/LocalhostTenantSwitcher';
import { useSetAppropriateMetaTitle } from './utils/customHooks/useSetAppropriateMetaTitle';
import { useActivateGoogleAnalytics } from './trackers/GA/useActivateGoogleAnalytics';
import PluginRoutes from './Plugins/routes';
import { PreAccessChecklistContextProvider } from './contexts/preAccessChecklistContext';
import RouteSomeAccessSomePlatformBuilderModule from './components/routing/RouteSomeAccessSomePlatformBuilderModule';
import { getAccessControlEnabledPLBModules } from './pageTpls/PlatformBuilder/config';
import { useQueryClient } from 'react-query';
import MomentLocale from './components/locale/MomentLocale';
import { LangSelectModal } from './LangSelectModal';
import { WorldEngine } from './pageTpls/WorldEngine';
import { InsightMatrix } from './components/analysisModules/InsightMatrix';
import { ShellPlayground } from './pageTpls/Playground';
import { AnnoBase } from './components/analysisModules/AnnoBase';



console.log('REACT VERSION: ', React.version)
const PlatformBuilder = React.lazy(() => import('./pageTpls/PlatformBuilder'))
// const ContentTypeLanding = React.lazy(() => import('./pageTpls/PlatformBuilder/subModules/ContentTypesManager/comps/ContentTypeLanding'))
const Synthesis = React.lazy(() => import('./components/Synthesis'))
const TCIFetch = React.lazy(() => import('./pageTpls/PlatformBuilder/subModules/ContentTypesManager/TCIFetch'))

const Home = React.lazy(() => import('./pageTpls/Home'))
const Playground = React.lazy(() => import('./pageTpls/Playground'))

const ContributeConfigure = React.lazy(() =>
	import('./pageTpls/ContributeConfigure'),
)
const Search = React.lazy(() => import('./pageTpls/Search'))
const Subscribe = React.lazy(() => import('./pageTpls/Subscribe'))
const ContributeTpl = React.lazy(() => import('./pageTpls/ContributeTpl'))
const ViewProfileUser = React.lazy(() =>
	import('./pageTpls/ViewProfile/ViewProfileUser'),
)

const UserProfilesListing = React.lazy(() =>
	import('./pageTpls/ProfilesListing'),
)


const PublishedListing = React.lazy(() => import('./pageTpls/PublishedListing'))
const PublishedPage = React.lazy(() => import('./pageTpls/PublishedPage'))
const RecommendationsPage = React.lazy(() =>
	import('./pageTpls/RecommendationsPage'),
)

const ModQueue = React.lazy(() => import('./pageTpls/ModQueue'))

const OKE404 = React.lazy(() => import('./pageTpls/OKE404'))
const OKFPublishSuccessful = React.lazy(() => import('./pageTpls/OKFPublishSuccessful'))
const Questionnaire = React.lazy(() => import('./pageTpls/Questionnaire'))
const Onboarding = React.lazy(() => import('./pageTpls/Onboarding'))
const UserAgreement = React.lazy(() => import('./pageTpls/UserAgreement'))
const GaAgreement = React.lazy(()=>import('./pageTpls/GaAgreement'))



//---learning modules -----//
const MyLearningModules = React.lazy(() =>
	import('./pageTpls/MyLearningModules'),
)
const LearningModuleLanding = React.lazy(() =>
	import('./pageTpls/LearningModuleLanding'),
)
//---END learning modules -----//

//----my dashboard -------//
const MyDashboard = React.lazy(() => import('./pageTpls/MyDashboard'))

const MyProfileEdit = React.lazy(() => import('./pageTpls/MyProfileEdit'))

/**
 * ---- auth routes ------- |
 */
const Signup = React.lazy(() => import('./pageTpls/Signup'))
const Login = React.lazy(() => import('./pageTpls/Login'))
const ForgotPassword = React.lazy(() => import('./pageTpls/auth/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./pageTpls/auth/ResetPassword'))
const AccountVerification = React.lazy(() =>
	import('./pageTpls/AccountVerification'),
)
const GoogleAuth = React.lazy(() => import('./pageTpls/GoogleAuth'))
/**
 * ---- END auth routes ---- |
 */

/**
 * ---- data routes ------- |
 */
const Data = React.lazy(() => import('./pageTpls/Data'))
// const DataPATHDrilldown1 = React.lazy(() =>
// 	import('./pageTpls/DataPATHDrilldown1'),
// )
// const DataPATHDrilldown2 = React.lazy(() =>
// 	import('./pageTpls/DataPATHDrilldown2'),
// )
/**
 * ---- END data routes ---- |
 */



const App = () => {

 
 
	//init hooks
	//phase this out, once we shift listings to react query
	// const { envConfigLoading } = useSelector((s) => s.environment)
	const { data: platformConfigs, status: platformConfigsStatus } = useGetPlatformConfigs()
	
	// fetch all tpls, and cache them separately as well
	const queryClient = useQueryClient()
	const {data: getTplsData, status: getTplsStatus} = useGetTplsAndCacheSeparately()
	/**
	 * why are we doing this complicated stuff below rather than simply checking getTplsStatus and 
	 * load app accordingly?
	 * Answer: we are being super safe here. if we rely on getTplsStatus, that will be 'success' 
	 * once the combined 'all tpls' cache is created. But, what we really care about is the 
	 * 'separately cached' tpls, and 'success' status doesnt tell us when that is done, since that
	 * will be completed a fractional few milliseconds later (since those caches are set in the 
	 * onSuccess callback). Hence we use this function below, instead of relying on getTplsStatus
	 */
	const atLeastOneSeparateTplCacheExists = (getTplsData) => {
		if(!getTplsData) return false;
		if(getTplsData.length === 0) return true //basically, if no templates exist yet on the platform, we still want the app to load
		if(!!queryClient.getQueryData(['tpl', getTplsData[0].kp_content_type])) return true
		// (above) : basically if at least one tpl cache is set, its a good enough proxy to know if all tpl caches are set.
		//else
		return false
	}


	// banner state 
	const {BANNER_STATE,REMOVE_ALERT_BANNER,REMOVE_INFO_BANNER}  = useBannerContext();
	
	/**
	 * fire this only after useGetTplsAndCacheSeparately cuz, it has a PWA related 
	 * function (__precachePrivateApis) which has a dependency on 'allTpls' cache
	 */
	const userData = useUserLoad({
		platformConfigsLoading: platformConfigsStatus === 'loading',
		platformConfigs,
		enabled: getTplsStatus === 'success'
	})
	const enableCoachmark = platformConfigs?.deployment?._EnableCoachmarks?.enable
	/**
	 * fire this only after useGetTplsAndCacheSeparately cuz, it has a PWA related 
	 * function (__precachePrivateApis) which has a dependency on 'allTpls' cache
	 */
	useRegisterSw({
		platformConfigs, 
		enabled: platformConfigsStatus === 'success' && getTplsStatus === 'success'
	});

	useOnlineStatusChange()

	/**
	 * PWA enabled apps, serve static files like index.html from serviceworker cache
	 * Hence, we dont get the opportunity to dynamically edit the meta tags as per tenant, in index.html 
	 * on the server side.
	 * 
	 * So we have to run this fix so that '__META_TITLE__' doesnt show up in the browser tab
	 */
	useSetAppropriateMetaTitle()
	
	
	useEffect(() => {
		

		if(platformConfigsStatus === 'success'){

			// const {_Branch} = platformConfigs;

			const { _Hotjar} = platformConfigs.deployment;
      // only if its the live production version of the platform
			if(
				process.env.REACT_APP_ENV === 'prod' && _Hotjar.enable &&
				process.env.NODE_ENV === 'production'
			){	
				hotjar.initialize(_Hotjar.id, _Hotjar.sv)
			}




		}

		if(userData.status === 'success'){
			Sentry.setUser({
				email: userData.data.user.email,
				username: userData.data.user.name,
				ip_address: "{{auto}}"
			})
		}

		

	}, [userData.status, platformConfigsStatus])

  useActivateGoogleAnalytics({platformConfigs, platformConfigsStatus,userData:userData.status === 'success'?userData.data:undefined})
	
	const [showWarningScreen, setShowWarningScreen] = useState(true)
	// useEffect(() => {
	// 	function findRects(){
	// 		const rects = coachMarkConfig[1]?.elements?.map((element) => {
	// 			const htmlElement = document.getElementById(element?.id);
	// 			return { rect: htmlElement?.getBoundingClientRect(), element: element?.element }
	// 		})
			
	// 		console.log({ rectsOnHome: rects })
	// 	}
		
	// 	window.addEventListener('scroll', findRects);
    //     window.addEventListener('resize', findRects);
      
    //     return () => {
    //       window.removeEventListener('scroll', findRects);
    //       window.removeEventListener('resize', findRects);
    //     };

	// }, [])
	const warningScreen = (process.env.REACT_APP_ENV === "staging" && showWarningScreen) ? true : false

	return (
		
		<Router history={history}>
			<PreAccessChecklistContextProvider>
			<ReactQueryDevtools initialIsOpen={false} toggleButtonProps={{style: {right: 0, left: 'unset'}}} />
			{(platformConfigsStatus === 'loading' || !atLeastOneSeparateTplCacheExists(getTplsData) || userData.status === 'loading') ? (

				<div>
					<Loader blockHeight='100vh' debug="app config loading"/>
				</div>
				

			) :  (
				
				<Fragment>
					
					<AppSettingsProvider>
					<MomentLocale />
					<OolibThemeProvider>
						{ (process.env.NODE_ENV !== 'production' 
							|| window.location.origin.includes('-dot-okf-fe-dev-dot-ok-framework.el.r.appspot.com')
							//|| process.env.REACT_APP_ENV === 'dev' activate when making dev a single url
							) && 
							<LocalhostTenantSwitcher/> }
						{ process.env.NODE_ENV === 'production' && <CacheStatus/> }
						{/** has to be here, and not in index, because it uses platformConfigs API */}
						{ process.env.REACT_APP_ENV === "staging" && showWarningScreen && 
          				<WarningScreen platformConfigs={platformConfigs} onClose={() => setShowWarningScreen(false)}/> }
						
						
							{
							platformConfigs?.deployment._UnderMaintenence.is ? (
								<MaintenanceScreen platformConfigs={platformConfigs} />
							) :
							(
								platformConfigs.deployment._BASE_PLATFORM_IS_SETUP === false &&
								userData.status === 'success'
							)
							? userData?.data?.user?.role === 'superAdmin'
								? <WorldEngine updateBasePlatformIsSetupFlag/>
								: <div>show platform not ready yet</div>
							: 
							(
								<Fragment>
									<GlobalStyles/>
									<div id="modal-root"></div> {/* we use createPortal to render modals, this div is used as refernce node*/}
									<div id="actionmenu-lightbox-root"/> {/** we use createProtal to render the actionmenu lightbox here IF its prop 'popOutOfOverflowHiddenParent' is set to true */}
									<BannerInfo BANNER_STATE={BANNER_STATE} REMOVE_INFO_BANNER = {REMOVE_INFO_BANNER} />
									<Header/>
									<LangSelectModal/>
									<BannerAlert BANNER_STATE={BANNER_STATE} REMOVE_ALERT_BANNER={REMOVE_ALERT_BANNER} />
									<div className='kp-content'>
									<Suspense fallback={<Loader blockHeight='calc(100vh - 6rem)' debug={'react lazy'}/>}>
                                    


											<Switch>
												
												{/**
												 * for some strange reason, 
												 * these dynamically generated plugin routes 
												 * have to be generated as sub routes as below
												 * else the rest of the routes below it dont work.
												 * 
												 * So lets leave this patterns as it is..
												 */}
												<Route path={"/plugins"}>
													<Switch>
									        			<PluginRoutes />    	
													</Switch>
												</Route>
												



												{/* {platformConfigs?.deployment._PrivatePlatform ? (
													<PrivateRoute
														exact={true}
														path='/'
														component={Home}
													/>
												) : ( */}
													{/* <Route exact={true} path='/' component={Home} /> */}
												{/* )} */}
											    <RouteAccessModule
													ACTION='READ'
													moduleId='home'
													exact={true}
													path='/'
													component={Home}
												/>
												<Route
													exact={true}
													path='/subscribe'
													component={Subscribe}
												/>
												<PrivateRoute
													exact={true}
													path='/playground'
													component={Playground}
												/>
												<PrivateRoute
													exact={true}
													path='/shellPlayground'
													component={ShellPlayground}
												/>
												<PrivateRoute
													exact={true}
													path='/my-dashboard'
													component={MyDashboard}
												/>
												<PrivateRoute
													exact={true}
													path='/my-dashboard/:SectionId'
													component={MyDashboard}
												/>
												<PrivateRoute
													exact={true}
													path='/contribute-config/:contentType'
													component={ContributeConfigure}
												/>
												<PrivateRoute
													exact={true}
													path='/choose-template'
													component={ChooseTemplate}
												/>
												{/* used only in millets. prob gonna phase out soon to something more standard. or not. */}
												<PrivateRoute
													exact={true}
													path='/recommendations-page/:recoObjectIds/:answerFilters'
													component={RecommendationsPage}
												/>

												{/* ---- auth ----- */}
												<Route exact={true} path='/login' component={Login} />
												<Route exact={true} path='/signup' component={Signup} />
												<Route
													exact={true}
													path='/verifyEmail/:token'
													component={AccountVerification}
												/>
												<PrivateRoute
													exact={true}
													path='/reset-password'
													component={ResetPassword}
												/>
												<Route
													exact={true}
													path='/googleAuth/:token'
													component={GoogleAuth}
												/>
												<Route
													exact={true}
													path='/verifyResetPasswordMail'
													component={ForgotPassword}
												/>
												<Route
													exact={true}
													path='/verifyResetPasswordMail/:token'
													component={ForgotPassword}
												/>
												{/* ---- END auth ----- */}

												{/* ---- data ----- */}
												{/* { platformConfigs.deployment._DataTpl && 
                    <Fragment> */}
												<RouteAccessModule
													ACTION='READ'
													moduleId='data'
													exact={true}
													path='/data'
													component={Data}
												/>
												{/* <RouteAccessModule
													ACTION='READ'
													moduleId='data'
													exact={true}
													path='/data/:drilldown1param'
													component={DataPATHDrilldown1}
												/>
												<RouteAccessModule
													ACTION='READ'
													moduleId='data'
													exact={true}
													path='/data/:drilldown1param/:drilldown2param'
													component={DataPATHDrilldown2}
												/> */}
												{/* </Fragment> } */}

												{/** Content Routes */}
												
												<RouteAccessOneContent
													ACTION='READ_IF_MEMBER'
													ignoreExtendedIsMemberCheck={true}
													exact={true}
													path='/published-listing/:contentType'
													// component={PublishedListingOld}
													component={PublishedListing}
												/>
												<Route
													/**
													 * if READ access exists then automatically 
													 * user will get access, member or not
													 */
													ACTION='READ_IF_MEMBER'
													exact={true}
													path='/published-page/:content_type'
													component={PublishedPage}
												/>
												<RouteAccessOneContent
													ACTION='UPDATE'
													exact={true}
													path='/edit/:content_type'
													component={ContributeTpl}
												/>
												{/* <RouteAccessOneContent ACTION='UPDATE' exact={true} path='/edit/:content_type' component={ContributeTpl} /> */}
												<RouteAccessSomeContent
													ACTION='READ'
													exact={true}
													path='/search'
													component={Search}
												/>


												{/** Moderation Routes */}

												<RouteAccessOneContent
													ACTION='MODERATE'
													exact={true}
													path='/moderate/:content_type'
													component={ContributeTpl}
												/>

												<RouteAccessSomeContent
													ACTION='MODERATE'
													exact={true}
													path='/mod-queue'
													component={ModQueue}
												/>

												{/* if deployment config flag turns this off, then dont render only */}
												{/* some kind of checkassesstomodule access control */}
												{/* { platformConfigs.deployment._EnableLearningModule &&
                      <Fragment> */}
												<RouteAccessModule
													ACTION='READ_MY'
													moduleId='learningModules'
													exact={true}
													path='/my-learning-modules'
													component={MyLearningModules}
												/>
												<RouteAccessModule
													ACTION='READ'
													moduleId='learningModules'
													exact={true}
													path='/learning-modules/:id'
													component={LearningModuleLanding}
												/>
												{/* </Fragment> } */}

												{/*--- pending update
                              |
                              |
                              V
                    */}

												<RouteAccessModule
													moduleId="analysisDashboard"
													ACTION='READ'
													exact={true}
													path='/synthesisV2'
													component={Synthesis}
												/>

												<RouteAccessSomeContent	
													ACTION='READ'
													exact={true}
													path='/insightMatrix'
													component={InsightMatrix}
												/>

												<RouteAccessSomeContent	
													ACTION='READ'
													exact={true}
													path='/annoBase'
													component={AnnoBase}
												/>


												<RouteAccessSomeContent
													ACTION='READ'
													exact={true}
													path='/dataPivot'
													component={DataPivot}
													/>

												{/* RouteReadOneProfile */}
												<Route
													exact={true}
													path='/profile/userProfiles/:profileType/:id'
													component={ViewProfileUser}
												/>

												<RouteAccessOneProfile
												    ACTION='READ'
													exact={true}
													path='/community-listing/:profileType'
													component={UserProfilesListing}
												/>

												<PrivateRoute
													exact={true}
													path='/search'
													component={Search}
												/>
												
												<PrivateRoute
													exact={true}
													path='/contribute-config'
													component={ContributeConfigure}
												/>

												<PrivateRoute
													exact={true}
													path='/my-edit/:profileType/:id'
													component={MyProfileEdit}
												/>
												{/* below two routes used for groups and orgs*/}

												{/* if deployment config flag turns this off, then dont render only */}
												{/* some kind of checkassesstomodule access control */}
												<PrivateRoute
													exact={true}
													path='/questionnaire'
													component={Questionnaire}
												/>

												{/* if deployment config flag turns this off, then dont render only */}
												<PrivateRoute
													exact={true}
													path='/onboarding'
													component={Onboarding}
												/>

												<PrivateRoute
													exact={true}
													path='/userAgreement'
													component={UserAgreement}
												/>

                                                <PrivateRoute
													exact={true}
													path='/gaAgreement'
													component={GaAgreement}
												/>

												
												
												{/** tci routes */}
												<RouteSuperAdmin
													exact={true}
													path='/tci/:contentType'
													component={TCIFetch}
												/>
												
												<RouteSomeAccessSomePlatformBuilderModule
													exact={true}
													path='/platformBuilder'
													ACTIONS={['READ', 'UPDATE']}
													modules={
														getAccessControlEnabledPLBModules().map(d => d.value)
													}
													component={PlatformBuilder}
												/>
												<RouteSomeAccessSomePlatformBuilderModule
													exact={true}
													path='/platformBuilder/:view'
													ACTIONS={['READ', 'UPDATE']}
													modules={
														getAccessControlEnabledPLBModules().map(d => d.value)
													}
													component={PlatformBuilder}
												/>
													<RouteSomeAccessSomePlatformBuilderModule
													exact={true}
													path='/platformBuilder/:view/:contentType'
													ACTIONS={['READ', 'UPDATE']}
													modules={
														getAccessControlEnabledPLBModules().map(d => d.value)
													}
													component={PlatformBuilder}
												/>

												<Route
													exact={true}
													path='/OKFPublishSuccessful'
													component={OKFPublishSuccessful}
												/>

												
												{/** 404 handles backwards compatibility as well to reroute to old links */}
												<Route component={OKE404} />
											</Switch>
										</Suspense>
									</div>
									<Footer />
								</Fragment>
							)
							}
						{ (enableCoachmark && userData?.data?.user && !warningScreen) && <Coachmark /> }
						</OolibThemeProvider>
					</AppSettingsProvider>
				</Fragment>
			)}
			</PreAccessChecklistContextProvider>
			
			</Router>
	)
}



export default Sentry.withProfiler(App);
