import styled from "styled-components";
import { colors, clampText } from "oolib";

const { lightPink, cyan, blue } = colors;

const bgColor = {
  quotes: lightPink,
  vocabs: cyan,
};


const cardBgColor = {

  SecondImmersionKenya:"#A4D6FF",
  ThirdImmersionKenya:"#FFA4B4",
  SecondImmersionNigeria:"#A4D6FF",
  ThirdImmersionNigeria:"#FFA4B4",
  FourthImmersionKenya:"#D7FFA3"

}



export const CardStyled = styled.div`
  padding:  2rem;

  background-color: ${({active,type})=>active?'white': cardBgColor[type]|| '#fdff98'}  ;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid ${({type})=>cardBgColor[type]|| '#fdff98'} ;
  gap: 2rem;
  justify-content: space-between;
  transition: background-color 0.3s ease-out;

  

  :hover {
    background-color: white;
  }
`;

export const CardHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap:1rem;
`;
export const CardBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

`;
export const CardFooterStyled = styled.div`
  /* margin-top: 2rem; */
  
`;

export const ClampedWrapperStyled = styled.div`
  p {
    font-weight: 500;
    span {
      display: inline;
      box-shadow: inset 0 -2px 0 ${blue};
      /* border-bottom: 2px solid #33a4ff; */
      background-color: ${({ annoTagType }) => bgColor[annoTagType]};
    }
  }
`;

export const SummaryWrapperStyled = styled.div`
  ${({noOfLines }) => 
    clampText( noOfLines || 3)
  }
  /* p {
    display: inline;
  } */
`;


