import { getVal } from 'oolib';
import { getTagTypeTitle } from '../../inputs/DynamicTagsInputs/FilterInput/utils/getTagTypeTitle';

export const frontendFilterViaFilterModule = ({data, activeFilters}) => {
  return data.filter(d => {
    let passesAllFilters = activeFilters.every(fil => {
      let passesSomeValue = fil.values.some(v => getVal(d, fil.target.valuePath) === v.value)
      return passesSomeValue;
    })
    return passesAllFilters;
  })  
}




const convertToInput = (activeFilters) => {
  const obj = {}

  for (let filter of activeFilters) {
    const arr = filter.values.map(({ value, display }) => ({ value, display }))

    obj[filter.filterId] = arr
  }

  return obj
}

const convertToOutput = ({ updated, filterConfig }) => {
  const result = Object.keys(updated).map((key) => ({
    values: updated[key].map((d) => ({ value: d.value, display: d.display })),
    target: filterConfig.find((d) => d.filterId === key).target,
    source: filterConfig.find((d) => d.filterId === key).source,
    filterId: key,
  }))

  return result


}

const getFilterDisplay =({filterConfig,filterId}) =>{
  const foundFilterCategoryindex = filterConfig.findIndex((d)=>d.filterId===filterId)   
  const foundConfig=filterConfig[foundFilterCategoryindex]  
 
  const gaCategory = (foundConfig && foundConfig.display) || ((foundConfig.source?.profileTypes?.length > 1) ? "Users" : (foundConfig.source?.profileTypes ? getTagTypeTitle({ tagType: foundConfig.source.profileTypes[0] }) : getTagTypeTitle({ tagType: foundConfig.source?.tagType })));
  return gaCategory
}


export { convertToInput, convertToOutput,getFilterDisplay };
