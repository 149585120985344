import React, { useEffect, useState } from "react";

import {
  BlockLabel,
  ButtonPrimary,
  Container,
  ImageInput,
  LABEL,
  PaddingTopBottom40,
  SkeletonLoader,
  TagDisplay,
  WrapperCardGrid,
  colors,
  getBreakPoint,
  makeArrayFromLength,
  useScreenWidth,
  icons,
  getKeyCode,
} from "oolib";
import FilterModule from "../../../../components/discovery/FilterModule";
import TagsInputMulti from "../../../../components/inputs/DynamicTagsInputs/TagsInputMulti";
import { useGetQueryData } from "../../../../utils/react-query-hooks/general";
import {
  useGetDataQuery
} from "../../../../utils/react-query-hooks/getData";
import { prepareData } from "./utils/prepareData";
import { StyledCaretRight, StyledCaretLeft, StyledModalWrapper, StyledModal, StyledActionWrapper, StyledTagsWrapper } from "./styled";

const { CaretRight, CaretLeft } = icons;

const MediaLibrary = (props) => {
  const contentTypes = [
    "launchTemplate",
    "routineVisits",
    "successStory",
    "needsFinding",
  ];

  const { data, status } = useGetDataQuery({
    contentTypes,
    findQuery: { kp_published_status: "published" },
    projection: {
      //needsFinding
      "main.images": 1,
      "tags.stakeholder": 1,
      "tags.subject": 1,
      "tags.conditionAreas": 1,
      "tags.teams" : 1,
      "main.facilityType": 1,
      //successStory, routineVisits & launchTemplate
      "main.imagesRepeater": 1,
      "meta.kp_content_type": 1,
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [showDwBtn, setShowDwBtn] = useState(undefined);

  const screenWidth = useScreenWidth();

  const someTagExists = (d) =>
    d.metaData.subject?.data?.length > 0 ||
    d.metaData.stakeholder?.data?.length > 0 ||
    !!d.metaData.facilityType === true;

  const [activeFilters, setActiveFilters] = useState([]);

  const allTpls = useGetQueryData("allTpls");
  const mediaCollection = data ? prepareData({ data, activeFilters }) : []

  const [ activeIndex, setActiveIndex ] = useState(0)

  useEffect(() => {
    const handleKeyDown = (event) => {
      let code = getKeyCode(event);
      if (code === 'ArrowRight') {
        setActiveIndex((prevIndex) => (prevIndex + 1) % mediaCollection.length); 
      } else if (code === 'ArrowLeft') {
        setActiveIndex((prevIndex) => (prevIndex - 1 + mediaCollection.length) % mediaCollection.length); 
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeIndex]);

  return (
    <div style={{ display: "flex" }}>
      {showModal && (
        <StyledModalWrapper>
          <StyledCaretLeft onClick={() => setActiveIndex((prevIndex) => (prevIndex - 1 + mediaCollection.length) % mediaCollection.length)}>
              <CaretLeft
                weight="duotone"
                size={75}
                color={colors.white}
              />
          </StyledCaretLeft>
          <StyledCaretRight onClick={() => setActiveIndex((prevIndex) => (prevIndex + 1) % mediaCollection.length)}>
              <CaretRight
                weight="duotone"
                size={75}
                color={colors.white}
              />
          </StyledCaretRight>
          <StyledModal style={{ width: screenWidth >= getBreakPoint("xl") ? "120rem" : "calc(100vw - 4rem)" }}>
            <StyledActionWrapper>
              <ButtonPrimary
                icon="DownloadSimple"
                onClick={() => window.open(mediaCollection[activeIndex].mediaLink, "_blank")}
              />
              <ButtonPrimary icon="X" onClick={() => setShowModal(false)} />
            </StyledActionWrapper>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: `${
                  someTagExists(mediaCollection[activeIndex]) ? "20rem" : ""
                } 1fr`,
              }}
            >
              {someTagExists(mediaCollection[activeIndex]) && (
                <StyledTagsWrapper>
                  <LABEL invert>TAGS</LABEL>
                  <div>
                    <BlockLabel label="Content Type" invert />
                    <TagDisplay
                      invert
                      display={
                        allTpls.find(
                          (d) =>
                            d.kp_content_type === mediaCollection[activeIndex].metaData.contentType
                        ).general.content.title
                      }
                    />
                  </div>
                  {mediaCollection[activeIndex].metaData.subject?.data?.length > 0 && (
                    <TagsInputMulti
                      label="Subject"
                      invert
                      readOnly
                      value={mediaCollection[activeIndex].metaData.subject}
                    />
                  )}
                  {mediaCollection[activeIndex].metaData.stakeholder?.data?.length > 0 && (
                    <TagsInputMulti
                      label="Stakeholder"
                      readOnly
                      invert
                      value={mediaCollection[activeIndex].metaData.stakeholder}
                    />
                  )}
                  {mediaCollection[activeIndex].metaData.facilityType && (
                     <div>
                     <BlockLabel label="Facility Type" invert />
                     <TagDisplay
                       invert
                       display={mediaCollection[activeIndex].metaData.facilityType.display}
                     />
                   </div>
                  )}
                </StyledTagsWrapper>
              )}
              <ImageInput
                invert
                readOnly
                value={[mediaCollection[activeIndex]]} //cuz on the tiles we want it all to be cover
                onChange={() => {}} //hack, otherwise caption will break the comp
              />
            </div>

          </StyledModal>
        </StyledModalWrapper>
      )}
        <FilterModule
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          orientation={"sidebar"}
          filterConfig={[
            {
              filterId: "contentType",
              display: "Content Type",
              source: {
                filterType: "staticType",
                options: allTpls
                  .filter((d) => contentTypes.includes(d.kp_content_type))
                  .map((d) => ({
                    display: d.general.content.title,
                    value: d.kp_content_type,
                  })),
              },
              target: {},
            },
            {
              filterId: "stakeholder",
              source: {
                filterType: "tagType",
                tagType: "stakeholder",
              },
              target: {},
            },
            {
              filterId: "subject",
              source: {
                filterType: "tagType",
                tagType: "subject",
              },
              target: {},
            },
            {
              filterId: "country",
              source:{
                filterType: "tagType",
                tagType: "country",
              },
              target: {},
            },
            {
              filterId: "states",
              source:{
                filterOptionsBy: { filterId: 'country' },
                filterType: "tagType",
                tagType: "states",
              },
              target: {},
            },
            {
              filterId: "districts",
              source:{
                filterOptionsBy: { filterId: 'states' },
                filterType: "tagType",
                tagType: "districts",
              },
              target: {},
            },
            {
              filterId: "facilityType",
              display: "Facility Type",
              source: {
                filterType: "tplType",
                contentType: "needsFinding",
                valuePath: "main.facilityType"
                // options: allTpls
                //   .filter((d) => contentTypes.includes(d.kp_content_type))
                //   .map((d) => ({
                //     display: d.general.content.title,
                //     value: d.kp_content_type,
                //   })),
              },
              target: {},
            },
            {
              filterId: "conditionAreas",
              source: {
                filterType: "tagType",
                tagType: "conditionAreas",
              },
              target: {},
            },
            {
              filterId: "teams",
              source: {
                filterType: "tagType",
                tagType: "teams",
              },
              target: {},
            },
            // {
            //   filterId: '3',
            //   source: {
            //     filterType: 'tagType',
            //     tagType: 'facilityType'
            //   },
            //   target: {}
            // },
          ]}
          // headerRef={headerRef}
          usedIn={"NKMS Media Library"}
        />

        
      <Container style={{ flexGrow: 1 }}>
          <PaddingTopBottom40>
            <WrapperCardGrid minWidth="15rem">
              {status === "loading" ? (
                makeArrayFromLength(40).map((d) => (
                  <SkeletonLoader style={{ height: "10rem" }} />
                ))
              ) : status === "error" ? (
                <div>Error</div>
              ) : (
                mediaCollection?.map((d, i) => {
                  return (
                    <div
                      key={d.id}
                      onMouseOver={() => setShowDwBtn(i)}
                      onMouseOut={() => setShowDwBtn(undefined)}
                      onClick={() => { setShowModal(true); setActiveIndex(i) }}
                      style={{ position: "relative", cursor: "pointer" }}
                    >
                      {showDwBtn === i && (
                        <ButtonPrimary
                          stopPropagation
                          preventDefault
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            zIndex: 10,
                          }}
                          icon="DownloadSimple"
                          onClick={() => window.open(d.mediaLink, "_blank")}
                        />
                      )}
                      <ImageInput
                        readOnly
                        value={[{ ...d, imageSpread: "cover" }]} //cuz on the tiles we want it all to be cover
                        enableCaptions={false}
                      />
                    </div>
                  );
                })
              )}
            </WrapperCardGrid>
          </PaddingTopBottom40>
        </Container>
    </div>
  );
};

export default MediaLibrary;
