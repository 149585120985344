import React, { useEffect, useState } from "react";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";
import { useGetDataQuery } from "../../../utils/react-query-hooks/getData";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";
import {
  Container,
  getVal,
  LABEL,
  makeArrayFromLength,
  PaddingTopBottom20,
  SANS_2,
  SkeletonLoader,
  TagLink,
  WrapperCardGrid,
} from "oolib";
import LexicalTextEditor from "../../inputs/TextEditor/LexicalTextEditor";
import { SidebarLayoutV2 } from "../../layout/SidebarLayoutV2";
import TagsInputSingle from "../../inputs/DynamicTagsInputs/TagsInputSingle";
import FilterModule from "../../discovery/FilterModule";
import { useElemInView } from "../../../utils/customHooks/useElemInView";
import ErrorStates from "../../ErrorStates";
import { __GetAllContentTypeConfig } from "../../../utils/getters/gettersV2";
import { MetaPrimary } from "../../generalUI/V2_MetaComps/MetaPrimary";

const getTplsWithAnnosEnabled = ({allTpls}) => {
  return allTpls
    .filter((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });
      return allBlocks.filter((d) => d.props?.annotation?.enable === true)?.length > 0
    })
}

const separateEachAnnoIntoIndependentDoc = ({ data, tplsInQuestion }) => {
  const annoFields = tplsInQuestion
    .map((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });
      return {
        contentType: tpl.kp_content_type,
        annoFields: allBlocks
          .filter((d) => d.props.annotation?.enable === true)
          .map((d) => d.valuePath),
      };
    })
    .reduce((a, b) => ({ ...a, [b.contentType]: b.annoFields }), {});

  return data.data
    .map((doc) => {
      const allAnnosInThisDoc = annoFields[doc.meta.kp_content_type]
        .map((valuePath) => {
          const annoDataForThisField = getVal(doc, valuePath)?.annoData;
          return (
            annoDataForThisField &&
            Object.keys(annoDataForThisField).map((d, i) => ({
              ...annoDataForThisField[d],
              annoKey: d,
            }))
          );
        })
        .filter(Boolean)
        .reduce((a, b) => [...a, ...b], []);

      return allAnnosInThisDoc.map((d) => ({
        ...d,
        docTags: doc.tags,
        _id: doc._id,
        meta: { kp_content_type: doc.meta.kp_content_type, contentTypeTitle: tplsInQuestion.find(d => d.kp_content_type === doc.meta.kp_content_type)?.general?.content?.title },
      }));
    })
    .reduce((a, b) => [...a, ...b], []);
};

const autoGenFilterConfigsFromTpl = ({ tplsInQuestion, _ProfileTypes }) => {
  let combinedBlocks = tplsInQuestion
    .map((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });
      return allBlocks;
    })
    .reduce((a, b) => [...a, ...b], []);

  combinedBlocks = combinedBlocks.filter((bl) => {
    return bl.valuePath.startsWith("tags.")
      ? true
      : bl.props?.annotation?.enable
      ? true
      : false;
  });

  const extractedTagTypes = combinedBlocks.reduce((a, b) => {
    if (b.valuePath.startsWith("tags.")) {
      return [...a, b.props.tagType];
    } else if (b.props.annotation?.enable) {
      return [...a, ...(b.props.annotation.tagTypesConfig?.map((d) => d.tagType) || [])];
    }
  }, []);

  const uniqueTags = new Set(extractedTagTypes);

  return Array.from(uniqueTags).map((d, i) => ({
    filterId: d,
    source: _ProfileTypes.some((p) => p.id === d)
      ? {
          filterType: "profileTagType",
          profileTypes: [d],
        }
      : {
          filterType: "tagType",
          tagType: d,
        },
    target: {},
  }));
  //now keep only the unique ones
};

const filterAsPerActiveFilters = ({ separatedData, activeFilters }) => {
  return separatedData.filter((d) => {
    if (
      activeFilters.every((a) =>
        a.values.some((v) => {
          
          return (
            d.tags?.[a.source.tagType || a.source.profileTypes?.[0]]?.data.some(
              (dd) => dd._id === v.value
            ) ||
            d.docTags?.[
              a.source.tagType || a.source.profileTypes?.[0]
            ]?.data.some((dd) => dd._id === v.value)
          );
        })
      )
    ) {
      return true;
    } else {
      return false;
    }
  });
};

const getProperTitleFromCollectionId = ({collectionId, allTpls}) => {
  const thisTpl = allTpls.find(d => d.kp_content_type === collectionId);
  if(!thisTpl || !thisTpl.general?.content?.title) return collectionId;
  //else
  return thisTpl.general.content.title;

}

const genTagSectionOnCard = ({tagsData, allTpls}) =>
  Object.values(tagsData).map((d) => {
    
    if (!d || !d?.data || d.data?.length === 0) return null;
    return (
      <div>
        <LABEL style={{ paddingBottom: "0.2rem" }}>{getProperTitleFromCollectionId({collectionId: d.collectionId, allTpls})}</LABEL>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {d.data?.map((dd) => (
            <TagLink
              XS
              display={dd.display}
              to={`/published-page/${dd.collectionId}?id=${dd._id}`}
            />
          ))}
        </div>
      </div>
    );
  });

export const AnnoBase = () => {
  
  const allTpls = useGetQueryData("allTpls");
  const { _ProfileTypes } = useGetQueryData("platformConfigs");
  const tplsInQuestion = getTplsWithAnnosEnabled({allTpls})

  const [activeFilters, setActiveFilters] = useState([]);

  //performance optimization
  const initialLimit = 20
  const [cardsLimit, setCardsLimit] = useState(initialLimit)
  const { observerRef } = useElemInView({ callback: () => setCardsLimit(prev => {
    return prev + initialLimit
  }) });

  
  // console.log({activeFilters})

  const fieldsToProject = tplsInQuestion
    .map((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });

      return allBlocks.filter((bl) => bl.props.annotation?.enable === true);
    })
    .reduce((a, b) => [...a, ...b], []);
  console.log({ fieldsToProject });

  const { data, status, error } = useGetDataQuery({
    contentTypes: tplsInQuestion.map(d => d.kp_content_type),
    findQuery: { kp_published_status: "published" },
    projection: {
      ...fieldsToProject.reduce(
        (a, b) => ({ ...a, [`${b.valuePath}.annoData`]: 1 }),
        {}
      ),
      tags: 1,
      "meta.kp_content_type": 1,
    },
  });

  const separatedData =
    status === "success" &&
    separateEachAnnoIntoIndependentDoc({ data, tplsInQuestion });

  const filterRes = autoGenFilterConfigsFromTpl({
    tplsInQuestion,
    _ProfileTypes,
  });
  console.log({ separatedData, filterRes });
  return (
    <div>
      <SidebarLayoutV2
        gridHeightCSS="calc( 100vh - 60px )"
        lsbComp={
          <div>
            <FilterModule
              orientation="sidebar"
              filterConfig={autoGenFilterConfigsFromTpl({
                tplsInQuestion,
                _ProfileTypes,
              })}
              {...{ activeFilters, setActiveFilters }}
            />
          </div>
        }
      >
        <Container>
          <PaddingTopBottom20>
            {separatedData && (
              <div style={{ paddingBottom: "1rem" }}>
                <SANS_2>{`Total Results: ${
                  filterAsPerActiveFilters({ separatedData, activeFilters })
                    ?.length
                }`}</SANS_2>
              </div>
            )}
            
              <WrapperCardGrid minWidth="40rem">
                {status === 'loading' 
                ? makeArrayFromLength(20).map((d,i) => <SkeletonLoader style={{height: '300px'}}/>)
                : status === 'error' 
                ? <ErrorStates errorResponseObject={error}/>
                : separatedData && filterAsPerActiveFilters({ separatedData, activeFilters })
                  .filter((d, i) => i < cardsLimit)
                  .map((singleAnno, singleAnnoI) => (
                    <div
                      key={singleAnno._id + singleAnno.annoKey}
                      style={{ border: "1px solid lightgrey" }}
                      ref={singleAnnoI === cardsLimit - 1 ? observerRef : null}
                    >
                      
                      
                      <div style={{ padding: "2rem" }}>
                      <div style={{paddingBottom: '1rem'}}>
                        <MetaPrimary
                          valuePath={"meta.contentTypeTitle"}
                          content={singleAnno}
                          align="left"
                          />
                        </div>  
                        <LexicalTextEditor
                          value={singleAnno.fragment}
                          readOnly
                        />
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid lightgrey",
                          padding: "2rem",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          {genTagSectionOnCard({tagsData: singleAnno.tags, allTpls})}
                        </div>
                        <div
                          style={{
                            marginTop: "1rem",
                            paddingTop: "1rem",
                            borderTop: "1px solid lightgrey",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          {genTagSectionOnCard({tagsData: singleAnno.docTags, allTpls})}
                        </div>
                      </div>
                    </div>
                  ))}
              </WrapperCardGrid>
            
          </PaddingTopBottom20>
        </Container>
      </SidebarLayoutV2>
    </div>
  );
};
