export const stockResearchNotesCardConfig = {
  cardHeader: [
    {
      comp: "Title",
      valuePath: "main.title",
    },
  ],

  cardBody: [
    {
      comp: "ClampedRichInputClone",
      valuePath: "annotations.tags.[annoTagType].data",
    },
  ],
  metaBlocks: [
    {
      comp: "Meta",
      props: {},
      useModifier: "date",
      valuePath: "kp_date_published",
    },
    {
      comp: "Meta",
      props: {},
      useModifier: "tplTitle",
      valuePath: "meta.kp_content_type",
    },
  ],
};

export const stockResearchNotesExpandCompConfig = {
  content: [
    {
      comp: "NewAnnotationsClone",
      valuePath: "annotations.tags.[annoTagType].data",
      label: "Annotations",
    },
  ],
};
